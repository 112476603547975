import Vue from 'vue';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import OsgoInsurance, { VehicleInfo } from '@/store/OsgoInsurance';
const osgoInsurance = getModule(OsgoInsurance, store);
export default Vue.extend({
    data() {
        return {
            osgoInsurance,
            programSelection: {
                otherRegistrationCountryName: '',
                otherRegistrationCountryId: null,
                vehicleType: 1,
                vehicleTypeName: '',
                registrationZone: 'vehicleRegisteredInUzb',
                uzbekistanRegion: 10,
                uzbekistanRegionName: '',
                insurancePeriod: '1_year',
                insurancePeriodName: '',
                driversAmount: 'unlimited',
                driversAmountName: '',
                useTerritoryRegion: 1,
            },
            rules: {
                otherRegistrationCountryName: [
                    { required: true, message: this.$t('Please_enter_Vehicle_registration_country'), trigger: 'blur' },
                ],
            },
        };
    },
    watch: {
        programSelection: {
            deep: true,
            // We have to move our method to a handler field
            handler(newVal, oldVal) {
                if ((this.programSelection.insurancePeriod === '6_months' && this.programSelection.registrationZone !== 'vehicleRegisteredInUzb') || ((this.programSelection.insurancePeriod === '15_days' || this.programSelection.insurancePeriod === '2_months') && this.programSelection.registrationZone !== 'vehicleRegisteredInOther')) {
                    this.programSelection.insurancePeriod = '1_year';
                }
                this.osgoInsurance.setProgramSelection(this.programSelection);
            }
        },
    },
    computed: {
        countriesToSelect() {
            const output = [];
            this.osgoInsurance.countriesList.forEach((country) => {
                if (Number(country.id) === 210) {
                    return;
                }
                const countryObj = {
                    label: country.name,
                    value: country.name,
                };
                output.push(countryObj);
            });
            return output;
        },
        vehicleTypesToSelect() {
            const output = [];
            this.osgoInsurance.vehicleTypes.forEach((type) => {
                const obj = {
                    label: type.name,
                    value: type.id,
                };
                output.push(obj);
            });
            return output;
        },
        uzbekistanRegionsToSelect() {
            const output = [];
            this.osgoInsurance.regionsList.forEach((region) => {
                const obj = {
                    label: region.name,
                    value: region.id,
                };
                output.push(obj);
            });
            return output;
        }
    },
    mounted() {
        if (this.osgoInsurance.programSelection) {
            this.programSelection = Object.assign({}, this.osgoInsurance.programSelection);
        }
        else {
            this.idValueChanged('vehicleType');
            this.idValueChanged('uzbekistanRegion');
            this.osgoInsurance.setProgramSelection(this.programSelection);
        }
        if (this.osgoInsurance.policyType === 'e-polis') {
            this.programSelection.driversAmount = 'unlimited';
            this.programSelection.insurancePeriod = '1_year';
            this.programSelection.registrationZone = 'vehicleRegisteredInUzb';
            this.osgoInsurance.setProgramSelection(this.programSelection);
        }
    },
    methods: {
        idValueChanged(type) {
            if (type === 'vehicleType' && this.programSelection.vehicleType) {
                const vehicleType = osgoInsurance.vehicleTypes.find((el) => {
                    return el.id === this.programSelection.vehicleType;
                });
                this.programSelection.vehicleTypeName = vehicleType.name;
            }
            else if (type === 'uzbekistanRegion' && this.programSelection.vehicleType) {
                const uzbekistanRegion = this.osgoInsurance.regionsList.find((el) => {
                    return el.id === this.programSelection.uzbekistanRegion;
                });
                this.programSelection.uzbekistanRegionName = uzbekistanRegion.name;
            }
            else if (type === 'otherCountryChanged' && this.programSelection.otherRegistrationCountryName) {
                const otherCountry = osgoInsurance.countriesList.find((el) => {
                    return el.name === this.programSelection.otherRegistrationCountryName;
                });
                this.programSelection.otherRegistrationCountryId = Number(otherCountry.id);
            }
        },
        prevStep() {
            this.osgoInsurance.setProgramSelection(this.programSelection);
            this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep - 1);
        },
        nextStep() {
            this.$refs['osgo-program-selection-form'].validate((valid) => {
                if (valid) {
                    this.osgoInsurance.setProgramSelection(this.programSelection);
                    this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep + 1);
                    let vehicleInfo = new VehicleInfo();
                    if (this.osgoInsurance.vehicleInfo) {
                        vehicleInfo = Object.assign(new VehicleInfo(), this.osgoInsurance.vehicleInfo);
                    }
                    vehicleInfo.country = this.programSelection.otherRegistrationCountryId;
                    vehicleInfo.countryName = this.programSelection.otherRegistrationCountryName;
                    vehicleInfo.uzbekistanRegion = this.programSelection.uzbekistanRegion;
                    vehicleInfo.uzbekistanRegionName = this.programSelection.uzbekistanRegionName;
                    if (this.programSelection.registrationZone === 'vehicleRegisteredInUzb') {
                        vehicleInfo.country = 210; // uzbekistan;
                        const uzbekistan = osgoInsurance.countriesList.find((el) => {
                            return Number(el.id) === 210;
                        });
                        vehicleInfo.countryName = uzbekistan.name;
                    }
                    this.osgoInsurance.setVehicleInfo(vehicleInfo);
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        }
    }
});
