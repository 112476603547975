import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import TravelInsurance from '@/store/TravelInsurance';
import CountryAndProgram from './step1-sub-steps/country-and-program.vue';
import GoalsAndDates from './step1-sub-steps/goals-and-dates.vue';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    components: {
        CountryAndProgram,
        GoalsAndDates,
    },
    data() {
        return {
            travelInsurance,
        };
    },
    methods: {},
});
