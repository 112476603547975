import Vue from "vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OsgoInsurance from "@/store/OsgoInsurance";
import OfflineFeatures from './components/policy-type-offline-features.vue';
import EpolisFeatures from './components/policy-type-e-polis-features.vue';
const osgoInsurance = getModule(OsgoInsurance, store);
export default Vue.extend({
    name: "PolicyTypeSelection",
    components: {
        OfflineFeatures,
        EpolisFeatures
    },
    data() {
        return {
            offlineDialogShow: false,
            ePolisDialogShow: false,
            policyTypeForm: {
                type: "e-polis",
            },
            rules: {},
            osgoInsurance,
        };
    },
    mounted() {
        this.policyTypeForm.type = this.osgoInsurance.policyType;
    },
    methods: {
        openProgramInfoDialog(type) {
            if (type === "offline") {
                this.offlineDialogShow = true;
            }
            else {
                this.ePolisDialogShow = true;
            }
        },
        nextStep() {
            this.$refs["applicant-form"].validate((valid) => {
                if (valid) {
                    this.osgoInsurance.setPolicyType(this.policyTypeForm.type);
                    this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep + 1);
                }
                else {
                    this.$message({
                        message: this.$t("Please_check_form"),
                        type: "warning",
                    });
                    return false;
                }
            });
        },
    },
});
