<template>
  <div id="vue-app">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <check-osgo-polis-form v-if="slug === 'check-osgo-polis'" :lang="lang" />
          <travel-insurance-form v-else-if="slug === 'travel-insurance'" :lang="lang" />
          <osgo-insurance-form v-else :lang="lang" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TravelInsuranceForm from './components/travel-insurance/index';
import OsgoInsuranceForm from './components/osgo-insurance/index';
import CheckOsgoPolisForm from './components/check-osgo-polis/index';

import TravelInsurance from '@/store/TravelInsurance';
import OsgoInsurance from '@/store/OsgoInsurance';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';

const travelInsurance = getModule(TravelInsurance, store);
const osgoInsurance = getModule(OsgoInsurance, store);


export default {
  name: 'App',
  components: {
    TravelInsuranceForm,
    OsgoInsuranceForm,
    CheckOsgoPolisForm
  },
  props: {
    lang: {
      type: String,
      required: true,
      default: 'ru',
    },
    slug: {
      type: String,
      required: true,
      default: 'travel-insurance',
    },
    userId: {
      required: false,
      default: null,
    }
  },

  mounted() {
    osgoInsurance.setUserId(this.userId);
    travelInsurance.setUserId(this.userId);
  }
};
</script>
