import Vue from 'vue';
import Element from 'element-ui';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
import enLocale from 'element-ui/lib/locale/lang/en';
import uzLocale from 'element-ui/lib/locale/lang/uz-UZ';
import PolicyTypeSelection from './policy-type-selection.vue';
import ProgramSelection from './steps/program-selection.vue';
import VehicleInfo from './steps/vehicle-info.vue';
import SelectPaymentMethod from '@/components/select-payment-method.vue';
import FinishStep from './steps/finish.vue';
import OsgoInsurance from '@/store/OsgoInsurance';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import { getParameterByName } from '@/utils/common';
const osgoInsurance = getModule(OsgoInsurance, store);
export default Vue.extend({
    components: {
        PolicyTypeSelection,
        ProgramSelection,
        VehicleInfo,
        SelectPaymentMethod,
        FinishStep,
    },
    props: {
        lang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            osgoInsurance,
        };
    },
    computed: {
        returnUrl() {
            if (this.osgoInsurance.orderId) {
                return 'https://new.apexinsurance.uz:3000/redirect/' + btoa(JSON.stringify({
                    type: 'osgo',
                    pageId: '19',
                    orderId: this.osgoInsurance.orderId,
                    userId: this.osgoInsurance.userId
                }));
            }
            return '';
        },
    },
    mounted() {
        this.setLanguage(this.lang);
        const url = window.location.href;
        if (url.includes('order_id=')) {
            const orderId = String(getParameterByName('order_id', url));
            this.osgoInsurance.setOrderId(orderId);
            this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentMaxStep);
        }
        else {
            this.osgoInsurance.fetchFondData().catch(() => {
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'warning'
                });
            });
        }
    },
    methods: {
        prevStep() {
            this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep - 1);
        },
        setLanguage(language) {
            let locale = ruLocale;
            if (language === 'uz') {
                locale = uzLocale;
            }
            else if (language === 'en') {
                locale = enLocale;
            }
            Element.locale(locale);
            this.$i18n.locale = language;
        },
        cashMethodSelected() {
            // cool;
            this.osgoInsurance.setIsPaid(true);
            this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep + 1);
        },
    },
});
