import Vue from 'vue';
import store from '@/store';
import TravelInsurance from '@/store/TravelInsurance';
import { getModule } from 'vuex-module-decorators';
import { InsurerPerson } from '@/store/InsuredPeople';
import { validateRegexPassport, validateLatinCharacters } from '@/utils/validators';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    props: {
        backBtn: {
            type: String,
            required: false,
            default: 'back'
        }
    },
    data() {
        return {
            birthdayPickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            fileList: [],
            hasAgreed: false,
            travelInsurance,
            insurerPersonForm: {},
            rules: {
                firstname: [
                    { required: true, message: this.$t('Please_enter_Firstname'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                middlename: [
                    { required: true, message: this.$t('Please_enter_Middlename'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                lastname: [
                    { required: true, message: this.$t('Please_enter_Lastname'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                birthDate: [
                    { required: true, message: this.$t('Please_enter_birth_day'), trigger: 'blur' },
                ],
                passport: [
                    { required: true, message: this.$t('Please_enter_passport_seria_and_number'), trigger: 'blur' },
                    { validator: validateRegexPassport, trigger: 'blur' }
                ],
                passportImage: [
                    { required: true, message: this.$t('Please_upload_passport_image'), trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: this.$t('Please_enter_phone'), trigger: 'blur' },
                ]
            },
        };
    },
    mounted() {
        if (this.travelInsurance.insurerPerson) {
            this.insurerPersonForm = Object.assign({}, this.travelInsurance.insurerPerson);
        }
        else {
            this.insurerPersonForm = new InsurerPerson();
        }
        if (this.insurerPersonForm.passportImage) {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.insurerPersonForm.passportImage.url = event.target.result;
                this.fileList.push(this.insurerPersonForm.passportImage);
            });
            reader.readAsDataURL(this.insurerPersonForm.passportImage.raw);
        }
    },
    methods: {
        fileUploadChange(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error(this.$t('Jpg_png_less'));
                this.$refs.fileUpload.clearFiles();
                return;
            }
            if (file) {
                this.insurerPersonForm.passportImage = file;
            }
            else {
                this.insurerPersonForm.passportImage = null;
            }
        },
        nextStep() {
            this.$refs['insurer-person-form'].validate((valid) => {
                if (valid) {
                    const obj = Object.assign(new InsurerPerson(), this.insurerPersonForm);
                    this.travelInsurance.setInsurer(obj);
                    this.$emit('submit-form');
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
    }
});
