/* eslint-disable */
import { getModule } from 'vuex-module-decorators';
import TravelInsurance from './TravelInsurance';
import store from '@/store';
import moment from 'moment';
const travelInsurance = getModule(TravelInsurance, store);
export class InsuredPerson {
    constructor(index) {
        this.birthDate = null;
        this.firstname = '';
        this.middlename = '';
        this.lastname = '';
        this.passportImage = null;
        this.phone = '';
        this.passport = '';
        this.passportSeria = '';
        this.passportNumber = '';
        this.address = '';
        this.isResponsible = false;
        this.index = 0;
        this.isParent = false;
        if (index) {
            this.index = index;
        }
    }
    get age() {
        return Math.abs(moment().diff(this.birthDate, 'years'));
    }
    get coeff() {
        if (travelInsurance.peopleAmount.code === 'family') {
            if (this.index === 0) {
                return 2.5;
            }
            return 0;
        }
        if (travelInsurance.globalCoeff <= 1 && this.ageCoeff < 1) {
            return Math.min(travelInsurance.globalCoeff, this.ageCoeff);
        }
        return Math.max(travelInsurance.globalCoeff, this.ageCoeff);
    }
    get sum() {
        return Math.round(travelInsurance.perEachSum * this.coeff * travelInsurance.dollarRate * 100) / 100;
    }
    get ageCoeff() {
        if (this.age <= 24 && travelInsurance.polisType) {
            return 0.8;
        }
        if (this.age >= 66 && this.age <= 70) {
            return 2.0;
        }
        if (this.age >= 71 && this.age <= 75) {
            return 3.0;
        }
        if (this.age >= 76 && this.age <= 80) {
            return 4.0;
        }
        if (this.age >= 81) {
            return 7.0;
        }
        return travelInsurance.globalCoeff;
    }
    get fullName() {
        return `${this.lastname} ${this.firstname} ${this.middlename}`;
    }
    getPassportImageBase64() {
        return new Promise((resolve, reject) => {
            if (this.passportImage) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    const base64Passport = {
                        name: this.passportImage.name,
                        file: event.target.result,
                        size: this.passportImage.size,
                        type: this.passportImage.raw.type,
                    };
                    resolve(base64Passport);
                });
                reader.readAsDataURL(this.passportImage.raw);
            }
            else {
                reject(false);
            }
        });
    }
}
export class InsurerPerson extends InsuredPerson {
}
