import Vue from 'vue';
import Element from 'element-ui';
import cbUzApi from '@/api/cbu-uz';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
import enLocale from 'element-ui/lib/locale/lang/en';
import uzLocale from 'element-ui/lib/locale/lang/uz-UZ';
import Step1Program from './steps/step1-program.vue';
import Step2Program from './steps/step2-insured-people.vue';
import SelectPaymentMethod from '@/components/select-payment-method.vue';
import FinishStep from './steps/finish-step.vue';
import TravelInsurance from '@/store/TravelInsurance';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import { getParameterByName } from '@/utils/common';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    components: {
        Step1Program,
        Step2Program,
        SelectPaymentMethod,
        FinishStep,
    },
    props: {
        lang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            travelInsurance,
        };
    },
    computed: {
        selectedCountries() {
            return this.travelInsurance.countriesSelected.map((country) => {
                return country[this.$i18n.locale + '_name'];
            }).join(', ');
        },
        programSelected() {
            if (this.travelInsurance.programSelected) {
                return this.travelInsurance.programSelected.name;
            }
            return '';
        },
        polisGoal() {
            if (this.travelInsurance.polisGoal) {
                return this.travelInsurance.polisGoal.name;
            }
            return '';
        },
        returnUrl() {
            if (this.travelInsurance.orderId) {
                return 'https://new.apexinsurance.uz:3000/redirect/' + btoa(JSON.stringify({
                    type: 'travel',
                    pageId: '17',
                    orderId: this.travelInsurance.orderId,
                    userId: this.travelInsurance.userId
                }));
            }
            return '';
        },
    },
    mounted() {
        this.setLanguage(this.lang);
        this.travelInsurance.setIsLoading(true);
        cbUzApi()
            .usdRate()
            .then(({ data }) => {
            if (data.status === 'Success' && data.data) {
                this.travelInsurance.setDollarRate(data.data);
                this.travelInsurance.setIsLoading(false);
            }
            else {
                this.$message({
                    message: this.$t('Error_happened_try_latter'),
                    type: 'warning'
                });
            }
        })
            .catch((res) => {
            this.$message({
                message: this.$t('Error_happened_try_latter'),
                type: 'warning'
            });
        });
        const url = window.location.href;
        if (url.includes('order_id=')) {
            const orderId = String(getParameterByName('order_id', url));
            this.travelInsurance.setOrderId(orderId);
            this.travelInsurance.setCurrentStep(this.travelInsurance.currentMaxStep);
        }
    },
    methods: {
        cashMethodSelected() {
            // cool;
            this.travelInsurance.setIsPaid(true);
            this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
        },
        prevStep() {
            this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep - 1);
        },
        setLanguage(language) {
            let locale = ruLocale;
            if (language === 'uz') {
                locale = uzLocale;
            }
            else if (language === 'en') {
                locale = enLocale;
            }
            Element.locale(locale);
            this.$i18n.locale = language;
        },
    },
});
