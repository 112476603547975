import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import OsgoInsurance from '@/store/OsgoInsurance';
import { validateLatinAndNumberCharacters, validateLatinCharacters, validateNumberCharacters, validateRegexPassportNumber, validateRegexPassportSeria, validateRegexPinfl } from '@/utils/validators';
import { OsgoApplicant } from '@/store/OsgoApplicant';
import { OsgoDriver } from '@/store/OsgoDriver';
import birthRelations from '../data/birthRelations.json';
import crmApi from '@/api/crm';
const osgoInsurance = getModule(OsgoInsurance, store);
export default Vue.extend({
    data() {
        return {
            osgoInsurance,
            vehicleOwnerForm: {},
            PINFLDialogShow: false,
            loading: false,
            rules: {
                firstname: [
                    { required: true, message: this.$t('Please_enter_Firstname'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                middlename: [
                    { required: true, message: this.$t('Please_enter_Middlename'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                lastname: [
                    { required: true, message: this.$t('Please_enter_Lastname'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
                phoneNumber: [
                    { required: true, message: this.$t('Please_enter_phone'), trigger: 'blur' },
                ],
                organizationName: [
                    { required: true, message: this.$t('Please_enter_Organization_name'), trigger: 'blur' },
                    { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
                ],
            },
        };
    },
    computed: {
        isUzbekistanCountry() {
            return this.vehicleOwnerForm.country === 210;
        },
        passportSeriaRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Passport_seria'), trigger: 'blur' },
            ];
            if (this.isUzbekistanCountry) {
                rules.push({ validator: validateLatinCharacters, trigger: ['change', 'blur'] }, { validator: validateRegexPassportSeria, trigger: 'blur' });
            }
            return rules;
        },
        passportNumberRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Passport_number'), trigger: 'blur' },
            ];
            if (this.isUzbekistanCountry) {
                rules.push({ validator: validateRegexPassportNumber, trigger: 'blur' });
            }
            return rules;
        },
        pinflRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_PINFL'), trigger: 'blur' },
            ];
            if (this.isUzbekistanCountry) {
                rules.push({ validator: validateRegexPinfl, trigger: 'blur' });
            }
            return rules;
        },
        innRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Inn'), trigger: 'blur' },
                { validator: validateLatinAndNumberCharacters, trigger: ['change', 'blur'] },
            ];
            if (this.isUzbekistanCountry) {
                rules.push({ validator: validateNumberCharacters, trigger: 'blur' });
            }
            return rules;
        },
        countriesToSelect() {
            const output = [];
            this.osgoInsurance.countriesList.forEach((country) => {
                const countryObj = {
                    label: country.name,
                    value: Number(country.id),
                };
                output.push(countryObj);
            });
            return output;
        },
        vehicleOwnerRelation() {
            return birthRelations.find((el) => {
                return el.id === -1;
            });
        },
    },
    mounted() {
        if (this.osgoInsurance.vehicleOwner) {
            this.vehicleOwnerForm = Object.assign(new OsgoApplicant(), this.osgoInsurance.vehicleOwner);
        }
        else {
            this.vehicleOwnerForm = new OsgoApplicant();
            this.vehicleOwnerForm.country = 210; // uzbekistan country;
            this.idValueChanged('country');
        }
        this.vehicleOwnerForm.couldNotFetch = false;
    },
    methods: {
        fetchInnData() {
            if (this.vehicleOwnerForm.inn) {
                this.loading = true;
                this.vehicleOwnerForm.couldNotFetch = false;
                crmApi().fetchInnData({
                    inn: this.vehicleOwnerForm.inn,
                }).then((res) => {
                    if (res && res.data && res.data.data) {
                        const { data } = res.data;
                        this.vehicleOwnerForm.organizationName = data.name;
                    }
                    else {
                        this.$message({
                            message: this.$t('We_could_not_fetch_data'),
                            duration: 5000,
                        });
                        if (this.osgoInsurance.policyType === 'offline')
                            this.vehicleOwnerForm.couldNotFetch = true;
                    }
                }).catch(() => {
                    this.$message({
                        message: this.$t('We_could_not_fetch_data'),
                        duration: 5000,
                    });
                    if (this.osgoInsurance.policyType === 'offline')
                        this.vehicleOwnerForm.couldNotFetch = true;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        fetchPinflData() {
            this.loading = true;
            this.vehicleOwnerForm.couldNotFetch = false;
            crmApi().fetchPinflData({
                pinfl: this.vehicleOwnerForm.pinfl,
                passportSeria: this.vehicleOwnerForm.passportSeria,
                passportNumber: this.vehicleOwnerForm.passportNumber
            }).then((res) => {
                if (res && res.data && res.data.data) {
                    const { data } = res.data;
                    this.vehicleOwnerForm.firstname = data.firstname;
                    this.vehicleOwnerForm.middlename = data.middlename;
                    this.vehicleOwnerForm.lastname = data.lastname;
                }
                else {
                    this.$message({
                        message: this.$t('We_could_not_fetch_data'),
                        duration: 5000,
                    });
                    if (this.osgoInsurance.policyType === 'offline')
                        this.vehicleOwnerForm.couldNotFetch = true;
                }
            }).catch(() => {
                this.$message({
                    message: this.$t('We_could_not_fetch_data'),
                    duration: 5000,
                });
                if (this.osgoInsurance.policyType === 'offline')
                    this.vehicleOwnerForm.couldNotFetch = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        openPINFLImage() {
            this.PINFLDialogShow = true;
        },
        prevStep() {
            this.osgoInsurance.setVehicleOwner(this.vehicleOwnerForm);
            this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep - 1);
        },
        idValueChanged(type) {
            if (type === 'country' && this.vehicleOwnerForm.country) {
                const country = this.osgoInsurance.countriesList.find((el) => {
                    return Number(el.id) === this.vehicleOwnerForm.country;
                });
                this.vehicleOwnerForm.countryName = country.name;
                if (this.isUzbekistanCountry) {
                    this.vehicleOwnerForm.firstname = '';
                    this.vehicleOwnerForm.lastname = '';
                    this.vehicleOwnerForm.middlename = '';
                }
            }
        },
        nextStep() {
            this.$refs['vehicle-owner-form'].validate((valid) => {
                if (valid) {
                    if (!this.isUzbekistanCountry) {
                        this.vehicleOwnerForm.pinfl = '33333111112222';
                    }
                    this.osgoInsurance.setVehicleOwner(this.vehicleOwnerForm);
                    const driverExistsIndex = this.osgoInsurance.drivers.findIndex((el) => {
                        return el.birthRelation === -1;
                    });
                    if (this.vehicleOwnerForm.isDriver) {
                        const newDriver = new OsgoDriver();
                        newDriver.firstname = this.vehicleOwnerForm.firstname;
                        newDriver.middlename = this.vehicleOwnerForm.middlename;
                        newDriver.lastname = this.vehicleOwnerForm.lastname;
                        newDriver.pinfl = this.vehicleOwnerForm.pinfl;
                        newDriver.passportSeria = this.vehicleOwnerForm.passportSeria;
                        newDriver.passportNumber = this.vehicleOwnerForm.passportNumber;
                        newDriver.country = this.vehicleOwnerForm.country;
                        newDriver.countryName = this.vehicleOwnerForm.countryName;
                        newDriver.birthRelation = -1;
                        newDriver.birthRelationName = this.vehicleOwnerRelation[this.$i18n.locale + '_name'];
                        newDriver.isApplicant = false;
                        newDriver.isOwner = true;
                        if (driverExistsIndex === -1) {
                            this.osgoInsurance.drivers.push(newDriver);
                        }
                        else {
                            const addDriver = Object.assign(new OsgoDriver(), this.osgoInsurance.drivers[driverExistsIndex]);
                            addDriver.firstname = this.vehicleOwnerForm.firstname;
                            addDriver.middlename = this.vehicleOwnerForm.middlename;
                            addDriver.lastname = this.vehicleOwnerForm.lastname;
                            addDriver.pinfl = this.vehicleOwnerForm.pinfl;
                            addDriver.passportSeria = this.vehicleOwnerForm.passportSeria;
                            addDriver.passportNumber = this.vehicleOwnerForm.passportNumber;
                            addDriver.country = this.vehicleOwnerForm.country;
                            addDriver.countryName = this.vehicleOwnerForm.countryName;
                            addDriver.isApplicant = false;
                            addDriver.isOwner = true;
                            const drivers = this.osgoInsurance.drivers.filter((el) => {
                                return el.birthRelation !== -1;
                            });
                            drivers.unshift(newDriver);
                            this.osgoInsurance.setDrivers(drivers);
                        }
                    }
                    else {
                        if (driverExistsIndex !== -1) {
                            const drivers = this.osgoInsurance.drivers.filter((el) => {
                                return el.birthRelation !== -1;
                            });
                            this.osgoInsurance.setDrivers(drivers);
                        }
                    }
                    if (!this.vehicleOwnerForm.isIndividual) {
                        const drivers = this.osgoInsurance.drivers.filter((el) => {
                            return el.birthRelation !== -1;
                        });
                        this.osgoInsurance.setDrivers(drivers);
                    }
                    this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep + 1);
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
    }
});
