import Vue from 'vue';
import store from '@/store';
import OsgoInsurance from '@/store/OsgoInsurance';
import { getModule } from 'vuex-module-decorators';
import VehicleInfoForm from './vehicle-info-sub-steps/vehicle-info-form.vue';
import VehicleApplicantForm from './vehicle-info-sub-steps/applicant-form.vue';
import VehicleOwnerForm from './vehicle-info-sub-steps/vehicle-owner-form.vue';
import VehicleDrivers from './vehicle-info-sub-steps/vehicle-drivers.vue';
import ConfirmPolis from './vehicle-info-sub-steps/confirm-polis.vue';
const osgoInsurance = getModule(OsgoInsurance, store);
export default Vue.extend({
    components: {
        VehicleApplicantForm,
        VehicleInfoForm,
        VehicleOwnerForm,
        VehicleDrivers,
        ConfirmPolis
    },
    data() {
        return {
            osgoInsurance,
            rules: {},
        };
    },
});
