export class OsgoApplicant {
    constructor() {
        this.isIndividual = true;
        this.couldNotFetch = false;
        this.inn = null;
        this.organizationName = null;
        this.isOwner = true;
        this.isDriver = true;
        this.firstname = null;
        this.middlename = null;
        this.lastname = null;
        this.passportImage = null;
        this.phoneNumber = null;
        this.pinfl = null;
        this.passportSeria = null;
        this.passportNumber = null;
        this.country = null;
        this.countryName = null;
    }
    get fullName() {
        return `${this.lastname} ${this.firstname} ${this.middlename}`;
    }
}
