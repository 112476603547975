import Vue from 'vue';
import store from '@/store';
import peopleAmountPlansList from '../data/peopleAmountPlansList.json';
import TravelInsurance from '@/store/TravelInsurance';
import { getModule } from 'vuex-module-decorators';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    data() {
        return {
            travelInsurance,
            peopleAmountPlansList,
            peopleAmount: {},
            rules: {},
        };
    },
    computed: {
        peopleAmountPlans() {
            let out = [];
            out = peopleAmountPlansList.slice();
            if (this.travelInsurance.polisGoal.code === 'work') {
                // remove family;
                out = out.filter((el) => {
                    return el.code !== 'family';
                });
            }
            return out;
        }
    },
    mounted() {
        if (this.travelInsurance.peopleAmount) {
            this.peopleAmount = Object.assign({}, this.travelInsurance.peopleAmount);
        }
        else {
            this.peopleAmount = Object.assign({}, peopleAmountPlansList[0]);
        }
    },
    methods: {
        selectPeopleAmount(peopleAmount) {
            this.peopleAmount = peopleAmount;
        },
        prevStep() {
            if (this.travelInsurance.step2SubStep > 0) {
                this.travelInsurance.setStep2SubStep(this.travelInsurance.step2SubStep - 1);
            }
            else {
                this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep - 1);
            }
        },
        nextStep() {
            this.$refs['insured-people-amount-form'].validate((valid) => {
                if (valid) {
                    this.travelInsurance.setPeopleAmount(this.peopleAmount);
                    if (this.travelInsurance.step2SubStep === this.travelInsurance.step2MaxSubStep) {
                        this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                    }
                    else {
                        this.travelInsurance.setStep2SubStep(this.travelInsurance.step2SubStep + 1);
                    }
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
    }
});
