import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import crmApi from '@/api/crm';
// eslint-disable-next-line
import store from '@/store';
import { i18n } from '@/main';
let OsgoInsurance = class OsgoInsurance extends VuexModule {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isPaid = false;
        this.orderId = null;
        this.tokenId = null;
        this.userId = null;
        this.currentStep = 0;
        this.step1SubStep = 0;
        this.step2SubStep = 0;
        // Next / prev buttons more generic
        this.currentMaxStep = 4;
        this.step1MaxSubStep = 0;
        this.step2MaxSubStep = 4;
        this.programSelection = null;
        this.vehicleInfo = null;
        this.drivers = [];
        this.vehicleOwner = null;
        this.osgoApplicant = null;
        this.policyType = "e-polis";
        this.countriesList = [];
        this.regionsList = [];
        this.relativesList = [];
        this.useTerritoryRegions = [];
        this.vehicleTypes = [];
        this.genders = [];
        this.districts = [];
    }
    setCountriesList(list) {
        this.countriesList = list;
    }
    setRegionsList(list) {
        this.regionsList = list;
    }
    setRelativesList(list) {
        this.relativesList = list;
    }
    setUseTerritoryRegions(list) {
        this.useTerritoryRegions = list;
    }
    setVehicleTypes(list) {
        this.vehicleTypes = list;
    }
    setGenders(list) {
        this.genders = list;
    }
    setDistricts(list) {
        this.districts = list;
    }
    setPolicyType(type) {
        this.policyType = type;
    }
    setOrderId(id) {
        this.orderId = id;
    }
    setTokenId(id) {
        this.tokenId = id;
    }
    setUserId(id) {
        this.userId = id;
    }
    setIsLoading(flag) {
        this.isLoading = flag;
    }
    setCurrentStep(step) {
        this.currentStep = step;
    }
    setStep1SubStep(step) {
        this.step1SubStep = step;
    }
    setStep2SubStep(step) {
        this.step2SubStep = step;
    }
    setProgramSelection(programSelection) {
        this.programSelection = programSelection;
    }
    setVehicleInfo(vehicleInfo) {
        this.vehicleInfo = vehicleInfo;
    }
    setDrivers(drivers) {
        this.drivers = drivers;
    }
    setVehicleOwner(vehicleOwner) {
        this.vehicleOwner = vehicleOwner;
    }
    setOsgoApplicant(osgoApplicant) {
        this.osgoApplicant = osgoApplicant;
    }
    setIsPaid(flag) {
        this.isPaid = flag;
    }
    get totalSum() {
        if (this.programSelection.registrationZone === 'vehicleRegisteredInUzb') {
            if (this.programSelection.insurancePeriod === '1_year') {
                if (this.programSelection.driversAmount === 'unlimited') {
                    if (this.programSelection.uzbekistanRegion === 10 || this.programSelection.uzbekistanRegion === 11) {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 168000;
                            case 6:
                                return 201600;
                            case 9:
                                return 201600;
                            case 15:
                                return 67200;
                            default:
                                return 0;
                        }
                    }
                    else {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 120000;
                            case 6:
                                return 144000;
                            case 9:
                                return 144000;
                            case 15:
                                return 48000;
                            default:
                                return 0;
                        }
                    }
                }
                else {
                    // max 5;
                    if (this.programSelection.uzbekistanRegion === 10 || this.programSelection.uzbekistanRegion === 11) {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 56000;
                            case 6:
                                return 67200;
                            case 9:
                                return 67200;
                            case 15:
                                return 22400;
                            default:
                                return 0;
                        }
                    }
                    else {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 40000;
                            case 6:
                                return 48000;
                            case 9:
                                return 48000;
                            case 15:
                                return 16000;
                            default:
                                return 0;
                        }
                    }
                }
            }
            else {
                // 6 months;
                if (this.programSelection.driversAmount === 'unlimited') {
                    if (this.programSelection.uzbekistanRegion === 10 || this.programSelection.uzbekistanRegion === 11) {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 117600;
                            case 6:
                                return 141120;
                            case 9:
                                return 141120;
                            case 15:
                                return 47040;
                            default:
                                return 0;
                        }
                    }
                    else {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 84000;
                            case 6:
                                return 100800;
                            case 9:
                                return 100800;
                            case 15:
                                return 33600;
                            default:
                                return 0;
                        }
                    }
                }
                else {
                    // max 5;
                    if (this.programSelection.uzbekistanRegion === 10 || this.programSelection.uzbekistanRegion === 11) {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 39200;
                            case 6:
                                return 47040;
                            case 9:
                                return 47040;
                            case 15:
                                return 15680;
                            default:
                                return 0;
                        }
                    }
                    else {
                        switch (this.programSelection.vehicleType) {
                            case 1:
                                return 28000;
                            case 6:
                                return 33600;
                            case 9:
                                return 33600;
                            case 15:
                                return 11200;
                            default:
                                return 0;
                        }
                    }
                }
            }
        }
        else {
            // other countries;
            if (this.programSelection.insurancePeriod === '15_days') {
                if (this.programSelection.driversAmount === 'unlimited') {
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 33600;
                        case 6:
                            return 40320;
                        case 9:
                            return 40320;
                        case 15:
                            return 13440;
                        default:
                            return 0;
                    }
                }
                else {
                    // max 5;
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 11200;
                        case 6:
                            return 13440;
                        case 9:
                            return 13440;
                        case 15:
                            return 4480;
                        default:
                            return 0;
                    }
                }
            }
            else if (this.programSelection.insurancePeriod === '2_months') {
                if (this.programSelection.driversAmount === 'unlimited') {
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 67200;
                        case 6:
                            return 80640;
                        case 9:
                            return 80640;
                        case 15:
                            return 26880;
                        default:
                            return 0;
                    }
                }
                else {
                    // max 5;
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 22400;
                        case 6:
                            return 26880;
                        case 9:
                            return 26880;
                        case 15:
                            return 8960;
                        default:
                            return 0;
                    }
                }
            }
            else {
                // 1 year
                if (this.programSelection.driversAmount === 'unlimited') {
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 168000;
                        case 6:
                            return 201600;
                        case 9:
                            return 201600;
                        case 15:
                            return 67200;
                        default:
                            return 0;
                    }
                }
                else {
                    // max 5;
                    switch (this.programSelection.vehicleType) {
                        case 1:
                            return 56000;
                        case 6:
                            return 67200;
                        case 9:
                            return 67200;
                        case 15:
                            return 22400;
                        default:
                            return 0;
                    }
                }
            }
        }
    }
    resetForm() {
        this.setCurrentStep(0);
    }
    async submitInsurance() {
        const programSelectionData = Object.assign({}, this.programSelection);
        programSelectionData.driversAmountName = i18n.t(programSelectionData.driversAmount);
        programSelectionData.insurancePeriodName = i18n.t(programSelectionData.insurancePeriod);
        const data = {
            totalSum: this.totalSum,
            programSelection: programSelectionData,
            vehicleInfo: this.vehicleInfo,
            drivers: this.drivers,
            vehicleOwner: this.vehicleOwner,
            osgoApplicant: this.osgoApplicant,
            userId: Number(this.userId),
            policyType: this.policyType
        };
        return new Promise((resolve, reject) => {
            const params = {};
            if (this.orderId && this.tokenId) {
                params.orderId = this.orderId;
                params.tokenId = this.tokenId;
            }
            crmApi().submitOsgoInsurance(data, params).then(({ data }) => {
                if (data.status === 'Success' && data.data) {
                    resolve(data.data);
                }
                else {
                    reject(false);
                }
            }).catch(() => {
                reject(false);
            });
        });
    }
    async fetchFondData() {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            crmApi().fetchFondData().then(({ data }) => {
                if (data.status === 'Success' && data.data) {
                    this.setCountriesList(data.data.citizenships);
                    this.setRegionsList(data.data.regions);
                    this.setRelativesList([
                        {
                            "id": -1,
                            "name": "Владелец ТС",
                            "ru_name": "Владелец ТС",
                            "en_name": "Vehicle owner",
                            "uz_name": "Transport vositasi egasi"
                        },
                        ...data.data.relatives
                    ]);
                    this.setUseTerritoryRegions(data.data.useTerritoryRegions);
                    this.setVehicleTypes(data.data.vehicleTypes);
                    this.setGenders(data.data.genders);
                    this.setDistricts(data.data.districts);
                    this.setIsLoading(false);
                    resolve(data.data);
                }
                else {
                    reject(false);
                }
            }).catch(() => {
                reject(false);
            });
        });
    }
};
__decorate([
    Mutation
], OsgoInsurance.prototype, "setCountriesList", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setRegionsList", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setRelativesList", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setUseTerritoryRegions", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setVehicleTypes", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setGenders", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setDistricts", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setPolicyType", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setOrderId", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setTokenId", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setUserId", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setIsLoading", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setCurrentStep", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setStep1SubStep", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setStep2SubStep", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setProgramSelection", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setVehicleInfo", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setDrivers", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setVehicleOwner", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setOsgoApplicant", null);
__decorate([
    Mutation
], OsgoInsurance.prototype, "setIsPaid", null);
__decorate([
    Action
], OsgoInsurance.prototype, "resetForm", null);
__decorate([
    Action
], OsgoInsurance.prototype, "submitInsurance", null);
__decorate([
    Action
], OsgoInsurance.prototype, "fetchFondData", null);
OsgoInsurance = __decorate([
    Module({
        name: 'OsgoInsurance',
        dynamic: true,
        store,
        namespaced: true,
    })
], OsgoInsurance);
export default OsgoInsurance;
export class VehicleInfo {
    constructor() {
        this.couldNotFetch = false;
        this.modelCustomName = null;
        this.govNumber = null;
        this.vehiclePassportSerial = null;
        this.vehiclePassportNumber = null;
        this.country = null;
        this.countryName = null;
        this.uzbekistanRegion = null;
        this.uzbekistanRegionName = null;
        this.issueYear = null;
        this.bodyNumber = null;
        this.engineNumber = null;
    }
}
