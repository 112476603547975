import Vue from 'vue';
import store from '@/store';
import TravelInsurance from '@/store/TravelInsurance';
import { InsuredPerson } from '@/store/InsuredPeople';
import { getModule } from 'vuex-module-decorators';
import { validateLatinCharacters } from '@/utils/validators';
import peopleAmountPlansList from '../data/peopleAmountPlansList.json';
import moment from 'moment';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    data() {
        return {
            birthdayPickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            travelInsurance,
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            insurerIndex: null,
            rules: {},
            form: {
                insuredPeople: [],
            }
        };
    },
    computed: {
        firstnameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Firstname'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        middlenameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Middlename'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        lastnameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Lastname'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        tooManyPeople() {
            return this.form.insuredPeople.length > this.travelInsurance.peopleAmount.max;
        },
    },
    mounted() {
        this.form.insuredPeople = [];
        if (this.travelInsurance.peopleAmount === 'team_1') {
            this.travelInsurance.setPeopleAmount(peopleAmountPlansList[0]);
        }
        this.travelInsurance.insuredPeople.forEach((person) => {
            const pp = new InsuredPerson(this.form.insuredPeople.length);
            for (const key in person) {
                pp[key] = person[key];
            }
            if (pp.passportImage) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    pp.passportImage.url = event.target.result;
                    this.fileList.push(pp.passportImage);
                });
                reader.readAsDataURL(pp.passportImage.raw);
            }
            this.form.insuredPeople.push(pp);
        });
        if (this.travelInsurance.polisGoal.code !== 'travel') {
            if (this.form.insuredPeople.length === 0) {
                this.addPerson();
            }
            else {
                this.form.insuredPeople = [this.form.insuredPeople[0]];
            }
        }
        else {
            while (this.form.insuredPeople.length < this.travelInsurance.peopleAmount.min) {
                this.addPerson();
            }
            if (this.travelInsurance.peopleAmount.code === 'family') {
                this.form.insuredPeople[0].isParent = true;
                this.form.insuredPeople[1].isParent = true;
            }
        }
        if (this.travelInsurance.insurerIndex !== null) {
            this.insurerIndex = this.travelInsurance.insurerIndex;
        }
        else {
            this.insurerIndex = 0;
        }
    },
    methods: {
        fileUploadChange(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error(this.$t('Jpg_png_less'));
                this.$refs.fileUpload.clearFiles();
                return;
            }
        },
        getPersonPassportNumberRules(index = null) {
            if (index === this.insurerIndex) {
                return [
                    { required: true, message: this.$t('Please_enter_Passport_number'), trigger: 'blur' },
                ];
            }
            return [];
        },
        getPersonPassportSeriaRules(index = null) {
            if (index === this.insurerIndex) {
                return [
                    { required: true, message: this.$t('Please_enter_Passport_seria'), trigger: 'blur' },
                ];
            }
            return [];
        },
        getPersonAddressRules(index = null) {
            if (index === this.insurerIndex) {
                return [
                    { required: true, message: this.$t('Please_enter_Address'), trigger: 'blur' },
                ];
            }
            return [];
        },
        getPassportImageRules(index = null) {
            if (index === this.insurerIndex) {
                return [
                    { required: true, message: this.$t('Please_upload_passport_image'), trigger: 'blur' },
                ];
            }
            return [];
        },
        getPhoneRules(index = null) {
            if (index === this.insurerIndex) {
                return [
                    { required: true, message: this.$t('Please_enter_phone'), trigger: 'blur' },
                ];
            }
            return [];
        },
        getBirthDateRules(index) {
            const person = this.form.insuredPeople[index];
            const birthDateValidator = async (rule, value, callback) => {
                const date1 = moment(value).startOf('day');
                const age = moment().diff(date1, 'years');
                if (person.isParent && age < 18) {
                    return callback(new Error(this.$t('Age_min_is_18')));
                }
                else if (!person.isParent && age > 24) {
                    return callback(new Error(this.$t('Age_max_is_24')));
                }
                return callback();
            };
            if (travelInsurance.peopleAmount.code === 'family') {
                return [
                    { required: true, message: this.$t('Please_enter_birth_day'), trigger: ['blur', 'change'] },
                    { validator: birthDateValidator, triger: ['blur', 'change'] }
                ];
            }
            return [
                { required: true, message: this.$t('Please_enter_birth_day'), trigger: ['blur', 'change'] },
            ];
        },
        prevStep() {
            this.travelInsurance.setInsuredPeople(this.form.insuredPeople);
            this.travelInsurance.setInsurerIndex(this.insurerIndex);
            if (this.travelInsurance.polisGoal.code === 'study' || this.travelInsurance.polisGoal.code === 'sport') {
                this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep - 1);
                return;
            }
            this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep - 1);
        },
        nextStep() {
            let file = null;
            if (this.$refs.fileUpload && this.$refs.fileUpload.length) {
                [file] = this.$refs.fileUpload[0].$refs['upload-inner'].fileList;
            }
            if (this.insurerIndex !== null) {
                if (file) {
                    this.form.insuredPeople[this.insurerIndex].passportImage = file;
                }
                else {
                    this.form.insuredPeople[this.insurerIndex].passportImage = null;
                }
            }
            this.$refs['insured-people-form'].validate((valid) => {
                if (valid) {
                    this.travelInsurance.setInsuredPeople(this.form.insuredPeople);
                    this.travelInsurance.setInsurerIndex(this.insurerIndex);
                    if (this.form.insuredPeople.length >= 5 && this.travelInsurance.peopleAmount.code === 'general') {
                        this.travelInsurance.setPeopleAmount(peopleAmountPlansList[1]);
                    }
                    if (this.travelInsurance.step2SubStep === this.travelInsurance.step2MaxSubStep) {
                        this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                    }
                    else {
                        this.travelInsurance.setStep2SubStep(this.travelInsurance.step2SubStep + 1);
                    }
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
        addPerson() {
            this.form.insuredPeople.push(new InsuredPerson(this.form.insuredPeople.length));
        },
        setInsurerPerson(index) {
            if (this.insurerIndex === index) {
                this.insurerIndex = null;
            }
            else {
                this.insurerIndex = index;
            }
        },
        removePersonMethod(index) {
            const person = this.form.insuredPeople[index];
            if (person.birthDate
                || person.firstname
                || person.middlename
                || person.lastname
                || person.phone
                || person.passport) {
                // not empty person;
                this.$confirm(this.$t('This_will_delete'), this.$t('Caution'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    this.form.insuredPeople.splice(index, 1);
                });
            }
            else {
                this.form.insuredPeople.splice(index, 1);
            }
        }
    }
});
