import Vue from 'vue';
import countriesList from '@/assets/data/countries.json';
import programsList from '../data/programsList.json';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import TravelInsurance from '@/store/TravelInsurance';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    data() {
        return {
            travelInsurance,
            programSelection: {
                countryNames: [],
                program: {},
            },
            rules: {
                countryNames: [
                    { required: true, message: this.$t('Please_select_country'), trigger: 'change' },
                ],
            },
            currentDialogProgram: {},
            programsList,
            programAllInfoDialog: false,
        };
    },
    computed: {
        isSchengenCountrySelected() {
            let flag = false;
            for (let i = 0; i < this.programSelection.countryNames.length; i += 1) {
                const country = countriesList.find((country) => {
                    return country.en_name === this.programSelection.countryNames[i];
                });
                if (country.isSchengen) {
                    flag = true;
                    break;
                }
            }
            return flag;
        },
        countriesToSelect() {
            const popularCountries = [
                'Russian Federation',
                'Germany',
                'France',
                'Japan'
            ];
            const groups = [
                {
                    label: this.$t('Popular_countries'),
                    options: []
                },
                {
                    label: this.$t('Schengen'),
                    options: []
                },
                {
                    label: this.$t('All_countries'),
                    options: []
                }
            ];
            countriesList.forEach((country) => {
                const countryObj = {
                    label: country[this.$i18n.locale + '_name'],
                    value: country.en_name,
                };
                if (popularCountries.indexOf(country.en_name) !== -1) {
                    groups[0].options.push(countryObj);
                }
                if (country.isSchengen) {
                    groups[1].options.push(countryObj);
                }
                groups[2].options.push(countryObj);
            });
            return groups;
        }
    },
    mounted() {
        if (this.travelInsurance.programSelected) {
            this.programSelection.program = Object.assign({}, this.travelInsurance.programSelected);
        }
        else {
            this.programSelection.program = programsList[1];
        }
        if (this.travelInsurance.countriesSelected) {
            this.programSelection.countryNames = this.travelInsurance.countriesSelected.map((country) => {
                return country.en_name;
            });
        }
    },
    methods: {
        checkContainsExcludedCountries(program) {
            let flag = false;
            for (let i = 0; i < this.programSelection.countryNames.length; i += 1) {
                if (program.excludedCountries.includes(this.programSelection.countryNames[i])) {
                    flag = true;
                    break;
                }
            }
            return flag;
        },
        countryChanged() {
            if (this.isSchengenCountrySelected) {
                let flag = false;
                for (let i = 0; i < this.programSelection.countryNames.length; i += 1) {
                    if (this.programSelection.countryNames[i] === 'Schengen') {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    this.programSelection.countryNames.push('Schengen');
                }
            }
            if (this.isSchengenCountrySelected && this.programSelection.program.name === 'SILVER') {
                this.programSelection.program = programsList[1];
            }
            if (this.programSelection.program.name === 'GOLD' && this.checkContainsExcludedCountries(this.programSelection.program)) {
                this.programSelection.program = programsList[2];
            }
        },
        openProgramInfoDialog(program) {
            this.currentDialogProgram = program;
            this.programAllInfoDialog = true;
        },
        prevStep() {
            if (this.travelInsurance.step1SubStep > 0) {
                this.travelInsurance.setStep1SubStep(this.travelInsurance.step1SubStep - 1);
            }
        },
        nextStep() {
            this.$refs['program-selection-form'].validate((valid) => {
                if (valid) {
                    const countriesSelcted = this.programSelection.countryNames.map((countryName) => {
                        return countriesList.find((country) => {
                            return country.en_name === countryName;
                        });
                    });
                    this.travelInsurance.setCountries(countriesSelcted);
                    this.travelInsurance.setProgramSelected(this.programSelection.program);
                    if (this.travelInsurance.step1SubStep === this.travelInsurance.step1MaxSubStep) {
                        this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                    }
                    else {
                        this.travelInsurance.setStep1SubStep(this.travelInsurance.step1SubStep + 1);
                    }
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        }
    }
});
