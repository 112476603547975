import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import TravelInsurance from '@/store/TravelInsurance';
import store from '@/store';
import InsuredPeopleForm from './step2-sub-steps/insured-people-form.vue';
import InsuredPeopleAmount from './step2-sub-steps/insured-people-amount.vue';
import ConfirmPolis from './step2-sub-steps/confirm-polis.vue';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    components: {
        InsuredPeopleAmount,
        InsuredPeopleForm,
        ConfirmPolis
    },
    data() {
        return {
            travelInsurance,
        };
    },
    methods: {},
});
