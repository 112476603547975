import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import crmApi from '@/api/crm';
import store from '@/store';
let TravelInsurance = class TravelInsurance extends VuexModule {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.isPaid = false;
        this.orderId = null;
        this.tokenId = null;
        this.userId = null;
        this.currentStep = 0;
        this.step1SubStep = 0;
        this.step2SubStep = 0;
        this.daysAmount = 0;
        this.dollarRate = 0;
        // Next / prev buttons more generic
        this.currentMaxStep = 3;
        this.step1MaxSubStep = 1;
        this.step2MaxSubStep = 1;
        this.programSelected = null;
        this.countriesSelected = [];
        this.polisType = null; // true - is once, false - multi
        this.insuranceStartDate = null;
        this.insuranceEndDate = null;
        this.polisGoal = null;
        this.multiSelected = null;
        this.peopleAmount = null;
        this.insurerIndex = null;
        this.insurerPerson = null;
        this.insuredPeople = [];
    }
    setOrderId(id) {
        this.orderId = id;
    }
    setTokenId(id) {
        this.tokenId = id;
    }
    setUserId(id) {
        this.userId = id;
    }
    setIsLoading(flag) {
        this.isLoading = flag;
    }
    setCurrentStep(step) {
        this.currentStep = step;
    }
    setStep1SubStep(step) {
        this.step1SubStep = step;
    }
    setStep2SubStep(step) {
        this.step2SubStep = step;
    }
    setPeopleAmount(peopleAmount) {
        this.peopleAmount = peopleAmount;
    }
    setCountries(countriesList) {
        this.countriesSelected = [...countriesList];
    }
    setProgramSelected(program) {
        this.programSelected = program;
    }
    setPolisType(type) {
        this.polisType = type;
    }
    setPolisGoal(goal) {
        this.polisGoal = goal;
    }
    setMultiSelected(multi) {
        this.multiSelected = multi;
    }
    setInsuranceStartDate(date) {
        this.insuranceStartDate = date;
    }
    setInsuranceEndDate(date) {
        this.insuranceEndDate = date;
    }
    setInsurerIndex(index) {
        this.insurerIndex = index;
    }
    setInsurer(insurer) {
        this.insurerPerson = insurer;
    }
    setDaysAmount(daysAmount) {
        this.daysAmount = daysAmount;
    }
    setInsuredPeople(people) {
        this.insuredPeople = people;
    }
    setDollarRate(dollarRate) {
        this.dollarRate = dollarRate;
    }
    setIsPaid(flag) {
        this.isPaid = flag;
    }
    get endDateWithSchengen() {
        if (this.insuranceEndDate) {
            const date = new Date(this.insuranceEndDate);
            date.setDate(date.getDate() + 15);
            return date;
        }
        return new Date();
    }
    get isSchengen() {
        let flag = false;
        for (let i = 0; i < this.countriesSelected.length; i += 1) {
            const el = this.countriesSelected[i];
            if (el.isSchengen) {
                flag = el.isSchengen;
                break;
            }
        }
        return flag;
    }
    get globalCoeff() {
        if (this.step2SubStep === this.step2MaxSubStep) {
            if (this.polisGoal.code === 'travel') {
                return this.peopleAmount.coeff;
            }
            if (this.polisGoal.code === 'work') {
                if (this.peopleAmount.code === 'general') {
                    return 1.1;
                }
                return this.peopleAmount.coeff;
            }
            if (this.polisGoal.code === 'sport') {
                return 1.7;
            }
            if (this.polisGoal.code === 'study') {
                return 0.735;
            }
        }
        return 0;
    }
    get perEachSum() {
        let coeff = 0;
        if (this.programSelected) {
            if (this.polisType) {
                if (this.daysAmount) {
                    for (let i = 0; i < this.programSelected.coeffs.length; i += 1) {
                        const el = this.programSelected.coeffs[i];
                        if (this.daysAmount >= el.from && this.daysAmount <= el.till) {
                            coeff = el.coeff;
                        }
                    }
                    return this.daysAmount * coeff;
                }
            }
            else if (this.multiSelected) {
                switch (this.programSelected.name) {
                    case 'SILVER':
                        coeff = this.multiSelected.silverPrice;
                        break;
                    case 'GOLD':
                        coeff = this.multiSelected.goldPrice;
                        break;
                    case 'PLATINUM':
                        coeff = this.multiSelected.platinumPrice;
                        break;
                    case 'STOPVIRUS I':
                        coeff = this.multiSelected.stopVirus1Price;
                        break;
                    case 'STOPVIRUS II':
                        coeff = this.multiSelected.stopVirus2rice;
                        break;
                    default:
                        break;
                }
                return coeff;
            }
        }
        return 0;
    }
    get totalSum() {
        let sum = 0;
        for (let i = 0; i < this.insuredPeople.length; i += 1) {
            sum += this.insuredPeople[i].sum;
        }
        return sum;
    }
    resetForm() {
        this.setInsuredPeople([]);
        this.setInsurer(null);
        this.setInsurerIndex(null);
        this.setPeopleAmount(null);
        this.setMultiSelected(null);
        this.setPolisGoal(null);
        this.setInsuranceStartDate(null);
        this.setInsuranceEndDate(null);
        this.setPolisType(null);
        this.setCountries([]);
        this.setProgramSelected(null);
        this.setDaysAmount(0);
        this.setStep1SubStep(0);
        this.setStep2SubStep(0);
        this.setCurrentStep(0);
    }
    async submitInsurance() {
        let insurer = this.insurerPerson;
        const passportImage = await insurer.getPassportImageBase64();
        insurer = Object.assign({}, insurer);
        insurer.passportImage = passportImage;
        const data = {
            totalSum: this.totalSum,
            daysAmount: this.daysAmount,
            programSelected: this.programSelected.name,
            countriesSelected: this.countriesSelected.map((el) => {
                return el.en_name;
            }),
            polisType: this.polisType,
            peopleAmount: this.peopleAmount.en_name,
            insuranceStartDate: this.insuranceStartDate,
            insuranceEndDate: this.insuranceEndDate,
            polisGoal: this.polisGoal.en_name,
            multiSelected: this.multiSelected.name,
            insurerPerson: insurer,
            insuredPeople: this.insuredPeople.map((person) => {
                return {
                    ...person,
                    phone: `998${person.phone}`,
                    passport: `${person.passportSeria}${person.passportNumber}`,
                };
            }),
            userId: Number(this.userId),
        };
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            const params = {};
            if (this.orderId && this.tokenId) {
                params.orderId = this.orderId;
                params.tokenId = this.tokenId;
            }
            crmApi().submitTravelInsurance(data, params).then(({ data }) => {
                if (data.status === 'Success' && data.data) {
                    resolve(data.data);
                }
                else {
                    reject(false);
                }
            }).catch(() => {
                reject(false);
            }).finally(() => {
                this.setIsLoading(false);
            });
        });
    }
};
__decorate([
    Mutation
], TravelInsurance.prototype, "setOrderId", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setTokenId", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setUserId", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setIsLoading", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setCurrentStep", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setStep1SubStep", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setStep2SubStep", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setPeopleAmount", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setCountries", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setProgramSelected", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setPolisType", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setPolisGoal", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setMultiSelected", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setInsuranceStartDate", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setInsuranceEndDate", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setInsurerIndex", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setInsurer", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setDaysAmount", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setInsuredPeople", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setDollarRate", null);
__decorate([
    Mutation
], TravelInsurance.prototype, "setIsPaid", null);
__decorate([
    Action
], TravelInsurance.prototype, "resetForm", null);
__decorate([
    Action
], TravelInsurance.prototype, "submitInsurance", null);
TravelInsurance = __decorate([
    Module({
        name: 'TravelInsurance',
        dynamic: true,
        store,
        namespaced: true,
    })
], TravelInsurance);
export default TravelInsurance;
