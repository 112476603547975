<template>
  <div class="payment-selection-method">
    <el-row :gutter="40">
      <el-col :lg="24">
        <div class="your-order-id">
          {{ $t('Your_order_id') }}: <span>{{ orderId }}</span>
          <p>{{ $t('Select_payment_method')  }}</p>
          <el-button type="default" @click="$emit('prev-step')" v-if="!noBackBtn">{{ $t('Back') }}</el-button>
        </div>
      </el-col>
      <el-col :lg="12">
        <h3>{{ $t('Pay_via_Payme') }}:</h3>
        <a :href="paymeUrl" class="payment-selection-method__logo">
          <img src="@/assets/images/payme.svg" alt="">
        </a>
      </el-col>
      <el-col :lg="12">
        <h3>{{ $t('Pay_via_Click') }}:</h3>
        <a :href="`https://my.click.uz/services/pay?service_id=17769&merchant_id=19625&amount=${amount}&transaction_param=${orderId}&return_url=${returnUrl}`" class="payment-selection-method__logo">
          <img src="@/assets/images/click.svg" alt="">
        </a>
      </el-col>
      <div class="clr"></div>
      <el-col :lg="12" v-if="!noCash">
        <h3>{{ $t('Pay_via_Cash') }}:</h3>
        <a href="#" @click="$emit('cash-money')" class="payment-selection-method__logo">
          <img src="@/assets/images/cash-money.svg" alt="" style="height: 200px;">
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'SelectPaymentMethod',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    returnUrl: {
      type: String,
      required: true,
    },
    noCash: {
      type: Boolean,
      default: false,
    },
    noBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    paymeUrl() {
      const token = `m=6026370fe62619df5ff73cfd;ac.orderId=${this.orderId};a=${this.amount * 100};c=${this.returnUrl}`;

      return 'https://checkout.paycom.uz/' + btoa(token);
    }
  }
}
</script>

<style lang="scss">
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
  .payment-selection-method {
    margin-top: 40px;
    padding-bottom: 50px;
    .your-order-id {
      font-size: 18px;
      margin-bottom: 30px;
      span {
        font-weight: bold;
      }
    }
    .payment-selection-method__logo {
      display: block;
      width: 100%;
      padding: 20px;
      // height: 200px;
      img {
        display: block;
        width: 100%;
      }

          display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
      }
      &:hover{
        &::before {
          border: $apex-primary-color solid 3px;
          border-radius: 5px;
          -webkit-animation-name: hvr-ripple-out;
          animation-name: hvr-ripple-out;
        }
      }
    }
  }
</style>