export class OsgoDriver {
    constructor(index) {
        this.isApplicant = false;
        this.isOwner = false;
        this.couldNotFetch = false;
        this.country = 210;
        this.countryName = '';
        this.firstname = '';
        this.middlename = '';
        this.lastname = '';
        this.pinfl = '';
        this.passportSeria = '';
        this.passportNumber = '';
        this.driversLicenseSeria = '';
        this.driversLicenseNumber = '';
        this.birthRelation = 0;
        this.birthRelationName = '';
        this.index = 0;
        if (index) {
            this.index = index;
        }
    }
    get fullName() {
        return `${this.lastname} ${this.firstname} ${this.middlename}`;
    }
    get driversLicense() {
        return `${this.driversLicenseSeria} ${this.driversLicenseNumber}`;
    }
}
