import { i18n } from '@/main';
export function validateRegexPlateNumber(rule, value, callback) {
    if (value && !(/[0-9]{2}[A-Z][0-9]{3}[A-Z]{2}$/.test(value)) && !(/[0-9]{2}[0-9]{3}[A-Z]{3}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_gov_number')));
    }
    return callback();
}
export function validateRegexPlateNumberIndividual(rule, value, callback) {
    if (value && !(/[0-9]{2}[A-Z][0-9]{3}[A-Z]{2}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_gov_number')));
    }
    return callback();
}
export function validateRegexPlateNumberCompany(rule, value, callback) {
    if (value && !(/[0-9]{5}[A-Z]{3}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_gov_number')));
    }
    return callback();
}
export function validateRegexPhoneNumber(rule, value, callback) {
    console.log(value);
    if (value && !(/\+998 \([0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_phone')));
    }
    return callback();
}
export function validateRegexPassport(rule, value, callback) {
    if (value && !(/[A-Z]{2}[0-9]{7}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_passport_seria_and_number')));
    }
    return callback();
}
export function validateRegexPassportSeria(rule, value, callback) {
    if (value && !(/[A-Z]{2}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Passport_seria')));
    }
    return callback();
}
export function validateRegexPassportNumber(rule, value, callback) {
    if (value && !(/[0-9]{7}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Passport_number')));
    }
    return callback();
}
export function validateRegexPinfl(rule, value, callback) {
    if (value && !(/[0-9]{14}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_PINFL')));
    }
    return callback();
}
export function validateRegexInn(rule, value, callback) {
    if (value && !(/[0-9]{1,10}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Inn')));
    }
    return callback();
}
export function validateRegexdriversLicenseNumber(rule, value, callback) {
    if (value && !(/[0-9]{1,7}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_PINFL')));
    }
    return callback();
}
export function validateRegexdriversLicenseSeria(rule, value, callback) {
    if (value && !(/[A-Z]{3}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_PINFL')));
    }
    return callback();
}
export function validateRegexYear(rule, value, callback) {
    if (value && !(/[0-9]{4}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_issue_year')));
    }
    return callback();
}
export function validateLatinAndNumberCharacters(rule, value, callback) {
    // if (value && !(/^[a-zA-Z0-9 ""О']+$/.test(value))) {
    //   return callback(new Error(<string>(i18n as any).t('Only_latin_and_numbers')));
    // }
    return callback();
}
export function validateNumberCharacters(rule, value, callback) {
    // if (value && !(/^[0-9]+$/.test(value))) {
    //   return callback(new Error(<string>(i18n as any).t('Only_numbers')));
    // }
    return callback();
}
export function validateLatinCharacters(rule, value, callback) {
    // if (value && !(/^[a-zA-Z '"`‘]+$/.test(value))) {
    //   return callback(new Error(<string>(i18n as any).t('Only_latin')));
    // }
    return callback();
}
export function validateRegexVehiclePassportSeria(rule, value, callback) {
    if (value && !(/[A-Z]{2}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_passport_seria')));
    }
    return callback();
}
export function validateRegexVehiclePassportNumber(rule, value, callback) {
    if (value && !(/[0-9]{1,10}$/.test(value))) {
        return callback(new Error(i18n.t('Please_enter_Vehicle_passport_number')));
    }
    return callback();
}
