import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import store from '@/store';
import OnlineInsuranceApp from './App.vue';
import './utils/filters';
import './assets/css/apex-style/theme/index.css';
import './assets/css/bootstrap-grid.min.css';
import './assets/css/main.scss';
import ru from './i18n/ru_messages.json';
import en from './i18n/en_messages.json';
import uz from './i18n/uz_messages.json';
Vue.directive('mask', {
    bind: function (el, binding) {
        let autoUnmask = false;
        if (binding.modifiers.auto_unmask) {
            autoUnmask = true;
        }
        const inputs = el.getElementsByTagName('INPUT');
        const input = inputs[0];
        new Inputmask(binding.value, {
            autoUnmask
        }).mask(input);
    },
    unbind: function (el) {
        const inputs = el.getElementsByTagName('INPUT');
        const input = inputs[0];
        Inputmask.remove(input);
    },
    update: function (el, binding) {
        const inputs = el.getElementsByTagName('INPUT');
        const input = inputs[0];
        Inputmask.remove(input);
        if (binding && binding.value) {
            let autoUnmask = false;
            if (binding.modifiers.auto_unmask) {
                autoUnmask = true;
            }
            new Inputmask(binding.value, {
                autoUnmask
            }).mask(input);
        }
    }
});
Vue.directive('uppercase', {
    inserted(el, _, vnode) {
        el.addEventListener('input', async function (e) {
            e.target.value = e.target.value.toUpperCase();
            vnode.componentInstance.$emit('input', e.target.value.toUpperCase());
        });
    },
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'ru',
    messages: { ru, en, uz },
});
const app = new Vue({
    i18n,
    store,
    el: '#vue-app',
    components: {
        OnlineInsuranceApp,
    },
});
window.app = app;
window._ = _;
export { i18n };
