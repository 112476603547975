import Vue from 'vue';
import multipleCoeffsList from '../data/multipleCoeffsList.json';
import polisGoals from '../data/polisGoals.json';
import peopleAmountPlansList from '../data/peopleAmountPlansList.json';
import store from '@/store';
import moment from 'moment';
import { calculateDaysDiff } from '@/utils/date';
import { getModule } from 'vuex-module-decorators';
import TravelInsurance from '@/store/TravelInsurance';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    data() {
        return {
            polisGoals,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            goalsAndDates: {
                goal: {},
                isOnce: true,
                startDate: '',
                endDate: '',
                multi: {},
                daysAmountInputValue: 1,
            },
            endDateWithSchengen: null,
            peopleAmountPlansList,
            peopleAmount: {},
            maxDaysError: false,
            extra15DaysInfo: travelInsurance.isSchengen,
            lastEndDate: null,
            rules: {
                startDate: [
                    { required: true, message: this.$t('Please_select_travel_start_date') },
                ],
                endDate: [
                    { required: true, message: this.$t('Please_select_travel_end_date') },
                ],
            },
            multipleCoeffsList,
            travelInsurance,
        };
    },
    computed: {
        endDateDefaultValue() {
            if (this.goalsAndDates.goal.code === 'study') {
                const date = new Date(this.goalsAndDates.startDate);
                date.setDate(date.getDate() + 183);
                return date;
            }
            return new Date(this.goalsAndDates.startDate);
        },
        endDatePickerOptions() {
            const that = this;
            return {
                firstDayOfWeek: 1,
                disabledDate(time) {
                    if (that.goalsAndDates.startDate) {
                        const lastDate = new Date(that.goalsAndDates.startDate);
                        if (that.goalsAndDates.goal.code === 'study') {
                            lastDate.setDate(lastDate.getDate() + 182);
                        }
                        return time.getTime() < (lastDate).getTime();
                    }
                    return false;
                },
            };
        },
        daysAmount() {
            if (this.goalsAndDates.startDate && this.goalsAndDates.endDate) {
                const b1 = moment(this.goalsAndDates.startDate).startOf('day');
                const b2 = moment(this.goalsAndDates.endDate).startOf('day');
                const days = Math.abs(b1.diff(b2, 'days')) + 1;
                return days;
            }
            return 0;
        },
    },
    mounted() {
        if (this.travelInsurance.insuranceStartDate) {
            this.goalsAndDates.startDate = new Date(this.travelInsurance.insuranceStartDate);
        }
        else {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 1);
            this.goalsAndDates.startDate = date;
        }
        if (this.travelInsurance.insuranceEndDate) {
            this.goalsAndDates.endDate = new Date(this.travelInsurance.insuranceEndDate);
        }
        else {
            const endDate = new Date(this.goalsAndDates.startDate);
            endDate.setDate(endDate.getDate() + this.goalsAndDates.daysAmountInputValue - 1);
            this.goalsAndDates.endDate = endDate;
        }
        this.goalsAndDates.daysAmountInputValue = calculateDaysDiff(this.goalsAndDates.startDate, this.goalsAndDates.endDate);
        if (this.travelInsurance.polisGoal) {
            this.goalsAndDates.goal = Object.assign({}, this.travelInsurance.polisGoal);
        }
        else {
            this.goalsAndDates.goal = Object.assign({}, polisGoals[0]);
        }
        if (this.travelInsurance.multiSelected) {
            this.goalsAndDates.multi = Object.assign({}, this.travelInsurance.multiSelected);
        }
        else {
            if (this.goalsAndDates.goal.minDays > 0) {
                this.goalsAndDates.multi = Object.assign({}, multipleCoeffsList[1]);
            }
            else {
                this.goalsAndDates.multi = Object.assign({}, multipleCoeffsList[0]);
            }
        }
        if (this.travelInsurance.polisType !== null) {
            this.goalsAndDates.isOnce = this.travelInsurance.polisType;
        }
        if (this.travelInsurance.peopleAmount) {
            this.peopleAmount = Object.assign({}, this.travelInsurance.peopleAmount);
        }
        const date = new Date(this.goalsAndDates.endDate);
        date.setDate(date.getDate() + 15);
        this.endDateWithSchengen = date;
    },
    watch: {
        'goalsAndDates.isOnce'() {
            this.occurancyChanged();
        },
        'goalsAndDates.goal'() {
            if (this.goalsAndDates.goal.code !== 'travel') {
                this.peopleAmount = {};
            }
            if (this.goalsAndDates.multi.maxDuration < this.goalsAndDates.goal.minDays) {
                this.goalsAndDates.multi = Object.assign({}, multipleCoeffsList[1]);
            }
            if (this.goalsAndDates.daysAmountInputValue < this.goalsAndDates.goal.minDays) {
                if (this.goalsAndDates.isOnce) {
                    this.goalsAndDates.endDate = '';
                    this.endDateSelected();
                }
                else {
                    this.multiChanged();
                }
            }
        },
    },
    methods: {
        selectPeopleAmount(peopleAmount) {
            if (peopleAmount.code === this.peopleAmount.code) {
                this.peopleAmount = {};
            }
            else {
                this.peopleAmount = peopleAmount;
            }
        },
        daysAmountInput: _.debounce(function (value) {
            if (value >= 2000 || (this.goalsAndDates.goal.code === 'study' && value < 183)) {
                this.goalsAndDates.endDate = '';
                return;
            }
            const endDate = new Date(this.goalsAndDates.startDate);
            endDate.setDate(Number(endDate.getDate()) + Number(value) - 1);
            this.goalsAndDates.endDate = endDate;
        }, 200),
        checkPriceExists(multi) {
            if (this.travelInsurance.programSelected.name === 'STOPVIRUS I' || this.travelInsurance.programSelected.name === 'STOPVIRUS II') {
                if (multi.stopVirus1Price === null || multi.stopVirus2Price === null) {
                    return false;
                }
            }
            return true;
        },
        endDateSelected() {
            if (this.goalsAndDates.endDate) {
                const daysAmount = calculateDaysDiff(this.goalsAndDates.startDate, this.goalsAndDates.endDate);
                this.goalsAndDates.daysAmountInputValue = daysAmount;
            }
            else {
                this.goalsAndDates.daysAmountInputValue = 0;
            }
            const date = new Date(this.goalsAndDates.endDate);
            date.setDate(date.getDate() + 15);
            this.endDateWithSchengen = date;
        },
        occurancyChanged() {
            this.endDateSelected();
            if (!this.goalsAndDates.startDate) {
                return;
            }
            if (this.goalsAndDates.isOnce) {
                if (this.lastEndDate) {
                    this.goalsAndDates.endDate = new Date(this.lastEndDate.getTime());
                    this.endDateSelected();
                }
                else {
                    this.goalsAndDates.endDate = null;
                }
            }
            else {
                if (this.goalsAndDates.endDate) {
                    this.lastEndDate = new Date(this.goalsAndDates.endDate.getTime());
                }
                if (this.goalsAndDates.startDate) {
                    const date = new Date();
                    date.setDate(this.goalsAndDates.startDate.getDate() + this.goalsAndDates.multi.maxDuration - 1);
                    this.goalsAndDates.endDate = date;
                    this.goalsAndDates.daysAmountInputValue = calculateDaysDiff(this.goalsAndDates.startDate, this.goalsAndDates.endDate);
                }
            }
        },
        startDateChanged(value) {
            if (!value) {
                this.goalsAndDates.endDate = '';
                return;
            }
            if (!this.goalsAndDates.isOnce) {
                const date = new Date();
                date.setDate(this.goalsAndDates.startDate.getDate() + this.goalsAndDates.multi.maxDuration - 1);
                this.goalsAndDates.endDate = date;
                this.goalsAndDates.daysAmountInputValue = calculateDaysDiff(this.goalsAndDates.startDate, this.goalsAndDates.endDate);
            }
        },
        multiChanged() {
            const date = new Date();
            date.setDate(this.goalsAndDates.startDate.getDate() + this.goalsAndDates.multi.maxDuration - 1);
            this.goalsAndDates.endDate = date;
            this.goalsAndDates.daysAmountInputValue = calculateDaysDiff(this.goalsAndDates.startDate, this.goalsAndDates.endDate);
        },
        nextStep() {
            this.$refs['goals-and-dates-form'].validate((valid) => {
                if (valid) {
                    this.travelInsurance.setPolisType(this.goalsAndDates.isOnce);
                    this.travelInsurance.setPolisGoal(this.goalsAndDates.goal);
                    this.travelInsurance.setInsuranceStartDate(this.goalsAndDates.startDate);
                    this.travelInsurance.setInsuranceEndDate(this.goalsAndDates.endDate);
                    this.travelInsurance.setMultiSelected(this.goalsAndDates.multi);
                    this.travelInsurance.setDaysAmount(Math.max(1, this.daysAmount));
                    if (this.goalsAndDates.goal.code === 'study' || this.goalsAndDates.goal.code === 'sport') {
                        this.travelInsurance.setPeopleAmount(peopleAmountPlansList[0]);
                        this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                        return;
                    }
                    if (this.peopleAmount.code !== 'family') {
                        this.peopleAmount = peopleAmountPlansList[0]; // general 1
                    }
                    this.travelInsurance.setPeopleAmount(this.peopleAmount);
                    if (this.travelInsurance.step1SubStep === this.travelInsurance.step1MaxSubStep) {
                        this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                    }
                    else {
                        this.travelInsurance.setStep1SubStep(this.travelInsurance.step1SubStep + 1);
                    }
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
        prevStep() {
            this.travelInsurance.setStep1SubStep(this.travelInsurance.step1SubStep - 1);
        }
    }
});
