import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import OsgoInsurance from '@/store/OsgoInsurance';
const osgoInsurance = getModule(OsgoInsurance, store);
import crmApi from '@/api/crm';
import Inputmask from 'inputmask';
export default Vue.extend({
    data() {
        return {
            osgoInsurance,
            loading: false,
            hasAgreed: false,
        };
    },
    computed: {
        driversPeopleData() {
            return osgoInsurance.drivers.map((person) => {
                return {
                    name: person.fullName,
                    driversLicense: person.driversLicense,
                    birthRelation: person.birthRelationName
                };
            });
        },
        polisOverallData() {
            const output = [
                {
                    key: this.$t('vehicleType'),
                    value: this.$t(this.osgoInsurance.programSelection.vehicleTypeName),
                },
                {
                    key: this.$t('registrationZone'),
                    value: this.osgoInsurance.vehicleInfo?.countryName
                }
            ];
            if (this.osgoInsurance.programSelection.registrationZone === 'vehicleRegisteredInUzb') {
                output.push({
                    key: this.$t('uzbekistanRegion'),
                    value: this.osgoInsurance.programSelection.uzbekistanRegionName
                });
            }
            output.push(...[
                {
                    key: this.$t('insurancePeriod'),
                    value: this.$t(this.osgoInsurance.programSelection.insurancePeriod)
                },
                {
                    key: this.$t('driversAmount'),
                    value: this.$t(this.osgoInsurance.programSelection.driversAmount)
                }
            ]);
            return output;
        },
        applicantOverallData() {
            const output = [
                {
                    key: this.$t('Person_type'),
                    value: this.osgoInsurance.osgoApplicant?.isIndividual ? this.$t('Individual_person') : this.$t('Company_person'),
                },
                {
                    key: this.$t('Country'),
                    value: this.osgoInsurance.osgoApplicant?.countryName,
                },
            ];
            if (this.osgoInsurance.osgoApplicant?.isIndividual) {
                if (this.osgoInsurance.osgoApplicant.country === 210) {
                    output.push({
                        key: this.$t('PINFL'),
                        value: this.osgoInsurance.osgoApplicant.pinfl
                    });
                }
                output.push(...[
                    {
                        key: this.$t('Passport_seria'),
                        value: this.osgoInsurance.osgoApplicant.passportSeria
                    },
                    {
                        key: this.$t('Passport_number'),
                        value: this.osgoInsurance.osgoApplicant.passportNumber
                    },
                    {
                        key: this.$t('Lastname'),
                        value: this.osgoInsurance.osgoApplicant.lastname
                    },
                    {
                        key: this.$t('Firstname'),
                        value: this.osgoInsurance.osgoApplicant.firstname
                    },
                    {
                        key: this.$t('Middlename'),
                        value: this.osgoInsurance.osgoApplicant.middlename
                    },
                ]);
            }
            else {
                output.push(...[
                    {
                        key: this.$t('Organization_name'),
                        value: this.osgoInsurance.osgoApplicant?.organizationName
                    },
                    {
                        key: this.$t('INN'),
                        value: this.osgoInsurance.osgoApplicant?.inn
                    },
                ]);
            }
            let osgoApplicantPhone = this.osgoInsurance.osgoApplicant?.phoneNumber;
            if (Number(this.osgoInsurance.osgoApplicant?.country) === 210) {
                osgoApplicantPhone = Inputmask.format(osgoApplicantPhone, {
                    mask: '+\\9\\98 (99) 999-99-99'
                });
            }
            output.push(...[
                {
                    key: this.$t('Phone'),
                    value: osgoApplicantPhone,
                },
                {
                    key: this.$t('Applicant_is_owner'),
                    value: this.osgoInsurance.osgoApplicant?.isOwner ? this.$t('Yes') : this.$t('No')
                },
            ]);
            return output;
        },
        ownerOverallData() {
            const output = [
                {
                    key: this.$t('Person_type'),
                    value: this.osgoInsurance.vehicleOwner?.isIndividual ? this.$t('Individual_person') : this.$t('Company_person'),
                },
                {
                    key: this.$t('Country'),
                    value: this.osgoInsurance.vehicleOwner?.countryName,
                },
            ];
            if (this.osgoInsurance.vehicleOwner?.isIndividual) {
                if (this.osgoInsurance.vehicleOwner?.country === 210) {
                    output.push({
                        key: this.$t('PINFL'),
                        value: this.osgoInsurance.vehicleOwner?.pinfl
                    });
                }
                output.push(...[
                    {
                        key: this.$t('Passport_seria'),
                        value: this.osgoInsurance.vehicleOwner.passportSeria
                    },
                    {
                        key: this.$t('Passport_number'),
                        value: this.osgoInsurance.vehicleOwner.passportNumber
                    },
                    {
                        key: this.$t('Lastname'),
                        value: this.osgoInsurance.vehicleOwner.lastname
                    },
                    {
                        key: this.$t('Firstname'),
                        value: this.osgoInsurance.vehicleOwner.firstname
                    },
                    {
                        key: this.$t('Middlename'),
                        value: this.osgoInsurance.vehicleOwner.middlename
                    },
                ]);
            }
            else {
                output.push(...[
                    {
                        key: this.$t('Organization_name'),
                        value: this.osgoInsurance.vehicleOwner?.organizationName
                    },
                    {
                        key: this.$t('INN'),
                        value: this.osgoInsurance.vehicleOwner?.inn
                    },
                ]);
            }
            return output;
        },
        vehicleInfoOverallData() {
            const output = [
                {
                    key: this.$t('Vehicle_model'),
                    value: this.osgoInsurance.vehicleInfo?.modelCustomName,
                },
                {
                    key: this.$t('Vehicle_issue_year'),
                    value: this.osgoInsurance.vehicleInfo?.issueYear,
                },
                {
                    key: this.$t('Vehicle_gov_number'),
                    value: this.osgoInsurance.vehicleInfo?.govNumber,
                },
                {
                    key: this.$t('Vehicle_body_number'),
                    value: this.osgoInsurance.vehicleInfo?.bodyNumber,
                },
                {
                    key: this.$t('Engine_number'),
                    value: this.osgoInsurance.vehicleInfo?.engineNumber,
                },
                {
                    key: this.$t('Vehicle_passport_seria'),
                    value: this.osgoInsurance.vehicleInfo?.vehiclePassportSerial,
                },
                {
                    key: this.$t('Vehicle_passport_number'),
                    value: this.osgoInsurance.vehicleInfo?.vehiclePassportNumber,
                },
            ];
            return output;
        }
    },
    mounted() {
        this.loading = true;
        crmApi().checkIsPaid(Number(this.osgoInsurance.orderId)).then((res) => {
            if (res && res.data && res.data.data) {
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'error',
                    duration: 50000,
                });
            }
            else {
                this.loading = false;
            }
        }).catch(() => {
            this.$message({
                message: this.$t('Something_went_wrong'),
                type: 'error',
                duration: 50000,
            });
        });
    },
    methods: {
        prevStep() {
            this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep - 1);
        },
        submitForm() {
            this.loading = true;
            this.osgoInsurance.submitInsurance().then((data) => {
                this.$message({
                    message: this.$t('Successfully_submitted'),
                    type: 'success',
                    duration: 5000,
                });
                this.osgoInsurance.setOrderId(data.orderId);
                this.osgoInsurance.setTokenId(data.tokenId);
                this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep + 1);
            }).catch((e) => {
                console.log(e);
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'error',
                    duration: 5000,
                });
            }).finally(() => {
                this.loading = false;
            });
        },
    }
});
