import Vue from 'vue';
import store from '@/store';
import OsgoInsurance, { VehicleInfo } from '@/store/OsgoInsurance';
import { getModule } from 'vuex-module-decorators';
import { validateLatinAndNumberCharacters, validateRegexYear } from '@/utils/validators';
const osgoInsurance = getModule(OsgoInsurance, store);
import crmApi from '@/api/crm';
export default Vue.extend({
    data() {
        return {
            fileList: [],
            osgoInsurance,
            vehicleInfo: {},
            loading: false,
            rules: {
                modelCustomName: [
                    { required: true, message: this.$t('Please_enter_Vehicle_model'), trigger: 'blur' },
                ],
                issueYear: [
                    { required: true, message: this.$t('Please_enter_Vehicle_issue_year'), trigger: 'blur' },
                    { validator: validateRegexYear, trigger: 'blur' }
                ],
                bodyNumber: [
                    { required: true, message: this.$t('Please_enter_Vehicle_body_number'), trigger: 'blur' },
                ],
                engineNumber: [
                    { required: true, message: this.$t('Please_enter_Engine_number'), trigger: 'blur' },
                ],
            },
        };
    },
    computed: {
        vehicleRegisteredInUzb() {
            return this.osgoInsurance.vehicleInfo?.country === 210;
        },
        govNumberRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Vehicle_gov_number'), trigger: 'blur' },
                { validator: validateLatinAndNumberCharacters, trigger: ['blur', 'change'] }
            ];
            return rules;
        },
        vehiclePassportSeriaRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Vehicle_passport_seria'), trigger: 'blur' },
                { validator: validateLatinAndNumberCharacters, trigger: ['blur', 'change'] }
            ];
            return rules;
        },
        vehiclePassportNumberRules() {
            const rules = [
                { required: true, message: this.$t('Please_enter_Vehicle_passport_number'), trigger: 'blur' },
                { validator: validateLatinAndNumberCharacters, trigger: 'blur' }
            ];
            return rules;
        }
    },
    async mounted() {
        if (this.osgoInsurance.vehicleInfo) {
            this.vehicleInfo = Object.assign(new VehicleInfo(), this.osgoInsurance.vehicleInfo);
        }
        else {
            this.vehicleInfo = new VehicleInfo();
        }
    },
    methods: {
        fetchVehicleData() {
            this.loading = true;
            this.vehicleInfo.couldNotFetch = false;
            crmApi().fetchVehicleData({
                govNumber: this.vehicleInfo.govNumber,
                vehiclePassportSeria: this.vehicleInfo.vehiclePassportSerial,
                vehiclePassportNumber: this.vehicleInfo.vehiclePassportNumber
            }).then((res) => {
                if (res && res.data && res.data.data) {
                    const { data } = res.data;
                    this.vehicleInfo.modelCustomName = data.modelName;
                    this.vehicleInfo.issueYear = data.issueYear;
                    this.vehicleInfo.bodyNumber = data.bodyNumber;
                    this.vehicleInfo.engineNumber = data.engineNumber;
                }
                else {
                    this.$message({
                        message: this.$t('We_could_not_fetch_data'),
                        duration: 5000,
                    });
                    if (this.osgoInsurance.policyType === 'offline')
                        this.vehicleInfo.couldNotFetch = true;
                }
            }).catch(() => {
                this.$message({
                    message: this.$t('We_could_not_fetch_data'),
                    duration: 5000,
                });
                if (this.osgoInsurance.policyType === 'offline')
                    this.vehicleInfo.couldNotFetch = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        prevStep() {
            this.osgoInsurance.setVehicleInfo(this.vehicleInfo);
            if (this.osgoInsurance.osgoApplicant.isOwner) {
                this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep - 2);
            }
            else {
                this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep - 1);
            }
        },
        nextStep() {
            this.$refs['vehicle-info-form'].validate((valid) => {
                if (valid) {
                    this.osgoInsurance.setVehicleInfo(this.vehicleInfo);
                    this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep + 1);
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
    }
});
