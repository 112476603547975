import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import InsurerPersonForm from '@/components/travel-insurance/steps/components/InsurerPersonForm.vue';
import TravelInsurance from '@/store/TravelInsurance';
import { InsurerPerson } from '@/store/InsuredPeople';
import crmApi from '@/api/crm';
const travelInsurance = getModule(TravelInsurance, store);
export default Vue.extend({
    components: {
        InsurerPersonForm
    },
    data() {
        return {
            travelInsurance,
            loading: false,
            hasAgreed: false,
        };
    },
    computed: {
        insuredPeopleData() {
            const filters = this.$options.filters;
            return travelInsurance.insuredPeople.map((person, index) => {
                return {
                    name: person.fullName,
                    birthDate: filters.formatDate(person.birthDate),
                    phone: person.phone,
                    passport: person.passport,
                    coeff: (person.coeff <= 1 ? Math.round((1 - person.coeff) * 100) : Math.round(person.coeff * 100)) + '%',
                    sum: this.$options.filters.formatThousands(person.sum) + ' ' + (this.$t('sum')),
                    isInsurer: (index === travelInsurance.insurerIndex ? this.$t('Yes') : this.$t('No')),
                    isParent: (person.isParent ? this.$t('Yes') : this.$t('No')),
                };
            });
        },
        polisOverallData() {
            const filters = this.$options.filters;
            let output = [
                {
                    key: this.$t('Visiting_countries'),
                    value: this.travelInsurance.countriesSelected.map((country) => {
                        return country[this.$i18n.locale + '_name'];
                    }).join(', '),
                },
                {
                    key: this.$t('Program'),
                    value: this.travelInsurance.programSelected.name,
                },
                {
                    key: this.$t('Polis_type'),
                    value: this.travelInsurance.polisType ? this.$t('Travel_once') : this.$t('Travel_many'),
                },
            ];
            if (this.travelInsurance.multiSelected && !this.travelInsurance.polisType) {
                output.push({
                    key: this.$t('Travel_many_plans'),
                    value: this.travelInsurance.multiSelected.name,
                });
            }
            output = output.concat([
                {
                    key: this.$t('Travel_start_date'),
                    value: filters.formatDate(this.travelInsurance.insuranceStartDate),
                },
                {
                    key: this.$t('Travel_end_date'),
                    value: filters.formatDate(this.travelInsurance.insuranceEndDate),
                },
            ]);
            if (this.travelInsurance.isSchengen) {
                output.push({
                    key: this.$t('Travel_end_date_with_schengen'),
                    value: filters.formatDate(this.travelInsurance.endDateWithSchengen),
                });
            }
            output = output.concat([
                {
                    key: this.$t('Travel_goal'),
                    value: this.travelInsurance.polisGoal[this.$i18n.locale + '_name'],
                },
                {
                    key: 'Кол-во лиц',
                    value: this.travelInsurance.insuredPeople.length,
                }
            ]);
            return output;
        },
    },
    mounted() {
        this.loading = true;
        crmApi().checkIsPaid(Number(this.travelInsurance.orderId)).then((res) => {
            if (res && res.data && res.data.data) {
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'error',
                    duration: 50000,
                });
            }
            else {
                this.loading = false;
            }
        }).catch(() => {
            this.$message({
                message: this.$t('Something_went_wrong'),
                type: 'error',
                duration: 50000,
            });
        });
    },
    methods: {
        prevStep() {
            this.travelInsurance.setStep2SubStep(this.travelInsurance.step2SubStep - 1);
        },
        submitForm() {
            let insurer = travelInsurance.insurerPerson;
            if (travelInsurance.insurerIndex !== null) {
                insurer = travelInsurance.insuredPeople[travelInsurance.insurerIndex];
            }
            insurer = Object.assign(new InsurerPerson(), insurer);
            this.travelInsurance.setInsurer(insurer);
            this.travelInsurance.submitInsurance().then((data) => {
                this.$message({
                    message: this.$t('Successfully_submitted'),
                    type: 'success',
                    duration: 5000,
                });
                this.travelInsurance.setOrderId(data.orderId);
                this.travelInsurance.setTokenId(data.tokenId);
                this.travelInsurance.setCurrentStep(this.travelInsurance.currentStep + 1);
                // this.travelInsurance.resetForm();
            }).catch((e) => {
                console.log(e);
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'error',
                    duration: 5000,
                });
            });
        },
    }
});
