import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import TravelInsurance from '@/store/TravelInsurance';
const travelInsurance = getModule(TravelInsurance, store);
import crmApi from '@/api/crm';
export default Vue.extend({
    components: {},
    data() {
        return {
            travelInsurance,
            loading: false,
        };
    },
    computed: {},
    mounted() {
        if (!this.travelInsurance.isPaid) {
            this.loading = true;
            crmApi().checkIsPaid(Number(this.travelInsurance.orderId)).then((res) => {
                if (!(res && res.data && res.data.data)) {
                    this.$message({
                        message: this.$t('Something_went_wrong'),
                        type: 'error',
                        duration: 50000,
                    });
                }
                else {
                    this.loading = false;
                }
            }).catch(() => {
                this.$message({
                    message: this.$t('Something_went_wrong'),
                    type: 'error',
                    duration: 50000,
                });
            });
        }
    },
    methods: {}
});
