import Vue from 'vue';
export default Vue.extend({
    name: 'CheckOsgoPolis',
    data() {
        return {
            hasAgreed: false,
            loading: false,
            polisInfo: {
                polisId: 'AA11123123',
                vehiclePlateNumber: '10A666DA',
                insuranceBeginDate: new Date(),
                insuranceEndDate: (new Date('2022-02-15')),
                polisPrice: 48000,
                branchName: "Ташкентский филиал"
            },
            polisFetched: false,
            checkOsgoPolisForm: {
                polisSerial: 'AA',
                polisNumber: '',
            },
            rules: {
                polisSerial: [
                    { required: true, message: this.$t('Please_enter_Polis_serial'), trigger: 'blur' },
                ],
                polisNumber: [
                    { required: true, message: this.$t('Please_enter_Polis_number'), trigger: 'blur' },
                ],
            }
        };
    },
    computed: {
        polisId() {
            return this.checkOsgoPolisForm.polisSerial + this.checkOsgoPolisForm.polisNumber;
        }
    },
    methods: {
        backToForm() {
            this.polisFetched = false;
        },
        submitForm() {
            this.$refs['check-osgo-polis-form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    // osgoPolisApi.getPolisInfo(this.polisId).then((res) => {
                    //   this.polisInfo = res.data;
                    //   this.polisFetched = true;
                    // }).cathc(() => {
                    //   this.$message({
                    //     message: <string>this.$t('Error_happened_try_latter'),
                    //     type: 'warning'
                    //   });
                    // }).finally(() => {
                    //   this.loading = false;
                    // })
                    setTimeout(() => {
                        this.polisFetched = true;
                        this.loading = false;
                    }, 1000);
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        }
    }
});
