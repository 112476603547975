import Vue from 'vue';
import store from '@/store';
import OsgoInsurance from '@/store/OsgoInsurance';
import { OsgoDriver } from '@/store/OsgoDriver';
import { getModule } from 'vuex-module-decorators';
import { validateLatinCharacters, validateRegexdriversLicenseNumber, validateRegexPassportNumber, validateRegexPassportSeria, validateRegexPinfl } from '@/utils/validators';
import birthRelations from '../data/birthRelations.json';
import { OsgoApplicant } from '@/store/OsgoApplicant';
const osgoInsurance = getModule(OsgoInsurance, store);
import crmApi from '@/api/crm';
export default Vue.extend({
    data() {
        return {
            osgoInsurance,
            PINFLDialogShow: false,
            rules: {},
            birthRelations,
            loading: false,
            form: {
                drivers: [],
            }
        };
    },
    computed: {
        firstnameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Firstname'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        middlenameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Middlename'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        lastnameRules() {
            return [
                { required: true, message: this.$t('Please_enter_Lastname'), trigger: 'blur' },
                { validator: validateLatinCharacters, trigger: ['change', 'blur'] },
            ];
        },
        countriesToSelect() {
            const output = [];
            this.osgoInsurance.countriesList.forEach((country) => {
                const countryObj = {
                    label: country.name,
                    value: Number(country.id),
                };
                output.push(countryObj);
            });
            return output;
        },
    },
    mounted() {
        this.form.drivers = [];
        this.osgoInsurance.drivers.forEach(async (person) => {
            const pp = new OsgoDriver(this.form.drivers.length);
            for (const key in person) {
                pp[key] = person[key];
            }
            pp.couldNotFetch = false;
            if ((pp.birthRelation < 0 || pp.isApplicant) && this.getIsPersonFromUzb(pp)) {
                await this.fetchFondData(pp);
            }
            this.form.drivers.push(pp);
        });
    },
    methods: {
        fetchFondData(person) {
            this.loading = true;
            person.couldNotFetch = false;
            const fetchPinflData = crmApi().fetchPinflData({
                pinfl: person.pinfl,
                passportSeria: person.passportSeria,
                passportNumber: person.passportNumber
            });
            const fetchDriversLicenseData = crmApi().fetchDriversLicenseData({
                pinfl: person.pinfl,
                passportSeria: person.passportSeria,
                passportNumber: person.passportNumber
            });
            Promise.all([fetchPinflData, fetchDriversLicenseData]).then((values) => {
                for (let i = 0; i < values.length; i++) {
                    const { data } = values[i];
                    if (!data.data || data.status !== 'Success') {
                        if (this.osgoInsurance.policyType === 'offline')
                            person.couldNotFetch = true;
                        throw new Error();
                    }
                }
                const personPassportInfo = values[0].data.data;
                const personDriversLicenseInfo = values[1].data.data;
                person.firstname = personPassportInfo.firstname;
                person.middlename = personPassportInfo.middlename;
                person.lastname = personPassportInfo.lastname;
                person.driversLicenseSeria = personDriversLicenseInfo.licenseSeria;
                person.driversLicenseNumber = personDriversLicenseInfo.licenseNumber;
            }).catch(() => {
                if (this.osgoInsurance.policyType === 'offline')
                    person.couldNotFetch = true;
                this.$message({
                    message: this.$t('We_could_not_fetch_data'),
                    duration: 5000,
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        getPersonPassportNumberRules(person) {
            const rules = [
                { required: true, message: this.$t('Please_enter_Passport_number'), trigger: 'blur' },
            ];
            if (this.getIsPersonFromUzb(person)) {
                rules.push({ validator: validateRegexPassportNumber, trigger: 'blur' });
            }
            return rules;
        },
        getPersonPassportSeriaRules(person) {
            const rules = [
                { required: true, message: this.$t('Please_enter_Passport_seria'), trigger: 'blur' },
            ];
            if (this.getIsPersonFromUzb(person)) {
                rules.push({ validator: validateLatinCharacters, trigger: ['change', 'blur'] }, { validator: validateRegexPassportSeria, trigger: 'blur' });
            }
            return rules;
        },
        getPersonDriversLicenseSeriaRules(person) {
            const rules = [
                { required: true, message: this.$t('Please_enter_Drivers_License_seria'), trigger: 'blur' },
            ];
            // if((this as any).getIsPersonFromUzb(person)) {
            //   rules.push(
            //     { validator: validateRegexdriversLicenseSeria, trigger: 'blur' }
            //   )
            // }
            return rules;
        },
        getPersonDriversLicenseNumberRules(person) {
            const rules = [
                { required: true, message: this.$t('Please_enter_Drivers_License_number'), trigger: 'blur' },
            ];
            if (this.getIsPersonFromUzb(person)) {
                rules.push({ validator: validateRegexdriversLicenseNumber, trigger: 'blur' });
            }
            return rules;
        },
        getPersonPinflRules(person) {
            const rules = [
                { required: true, message: this.$t('Please_enter_PINFL'), trigger: 'blur' },
                { validator: validateRegexPinfl, trigger: 'blur' }
            ];
            return rules;
        },
        getIsPersonFromUzb(person) {
            return person.country === 210;
        },
        idValueChanged(person, type) {
            if (type === 'country' && person.country) {
                const country = this.osgoInsurance.countriesList.find((el) => {
                    return Number(el.id) === person.country;
                });
                person.countryName = country.name;
            }
            else if (type === 'birth-relation') {
                const birthRelation = birthRelations.find((el) => {
                    return Number(el.id) === person.birthRelation;
                });
                person.birthRelationName = birthRelation[this.$i18n.locale + '_name'];
            }
        },
        getBirthRelationsList(person) {
            return this.osgoInsurance.relativesList.filter((relation) => {
                if (this.osgoInsurance.programSelection.driversAmount === 'unlimited') {
                    return (relation.id !== -1 && relation.id !== 0) || (relation.id === -1 && person.birthRelation < 0);
                }
                return (relation.id !== -1) || (relation.id === -1 && person.birthRelation < 0);
            });
        },
        openPINFLImage() {
            this.PINFLDialogShow = true;
        },
        updateApplicantVehicleOwner(drivers) {
            drivers.forEach((person) => {
                if (person.isApplicant) {
                    const applicant = Object.assign(new OsgoApplicant(), this.osgoInsurance.osgoApplicant);
                    applicant.country = person.country;
                    applicant.countryName = person.countryName;
                    this.osgoInsurance.setOsgoApplicant(applicant);
                }
            });
        },
        prevStep() {
            this.osgoInsurance.setDrivers(this.form.drivers);
            if (this.osgoInsurance.step2SubStep > 0) {
                this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep - 1);
            }
            else {
                this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep - 1);
            }
        },
        nextStep() {
            this.$refs['osgo-vehicle-drivers-form'].validate((valid) => {
                if (valid) {
                    // const [file] = this.$refs.fileUpload.$refs['upload-inner'].fileList;
                    // if (file) formData.append('avatar', file.raw);
                    this.form.drivers.forEach((person) => {
                        if (!this.getIsPersonFromUzb(person)) {
                            person.pinfl = '33333111112222'; // Custom PINFL;
                        }
                    });
                    this.osgoInsurance.setDrivers(this.form.drivers);
                    if (this.osgoInsurance.step2SubStep === this.osgoInsurance.step2MaxSubStep) {
                        this.osgoInsurance.setCurrentStep(this.osgoInsurance.currentStep + 1);
                    }
                    else {
                        this.osgoInsurance.setStep2SubStep(this.osgoInsurance.step2SubStep + 1);
                    }
                }
                else {
                    this.$message({
                        message: this.$t('Please_check_form'),
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
        addPerson() {
            const driver = new OsgoDriver(this.form.drivers.length);
            if (this.osgoInsurance.programSelection.driversAmount === 'unlimited') {
                driver.birthRelation = 1;
            }
            this.form.drivers.push(driver);
            this.idValueChanged(this.form.drivers[this.form.drivers.length - 1], 'country');
            this.idValueChanged(this.form.drivers[this.form.drivers.length - 1], 'birth-relation');
        },
        removePersonMethod(index) {
            const person = this.form.drivers[index];
            if (person.firstname
                || person.middlename
                || person.lastname
                || person.driversLicenseSeria
                || person.driversLicenseNumber
                || person.pinfl
                || person.passportSeria
                || person.passportNumber) {
                // not empty person;
                this.$confirm(this.$t('This_will_delete'), this.$t('Caution'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    this.form.drivers.splice(index, 1);
                });
            }
            else {
                this.form.drivers.splice(index, 1);
            }
        }
    }
});
