import axios from 'axios';
const baseUrl = process.env.VUE_APP_CRM_MIDDLEWARE_SERVICE_URL;
export default () => ({
    submitTravelInsurance: (data, params) => {
        let url = `${baseUrl}/travel-insurance`;
        if (params && params.orderId && params.tokenId) {
            url = `${baseUrl}/travel-insurance?orderId=${params.orderId}&tokenId=${params.tokenId}`;
        }
        return axios.post(url, data);
    },
    submitOsgoInsurance: (data, params) => {
        let url = `${baseUrl}/osgo-insurance`;
        if (params && params.orderId && params.tokenId) {
            url = `${baseUrl}/osgo-insurance?orderId=${params.orderId}&tokenId=${params.tokenId}`;
        }
        return axios.post(url, data);
    },
    fetchInnData: (data) => {
        return axios.post(`${baseUrl}/inn-data`, data);
    },
    fetchPinflData: (data) => {
        return axios.post(`${baseUrl}/pinfl-data`, data);
    },
    fetchDriversLicenseData: (data) => {
        return axios.post(`${baseUrl}/driver-license-data`, data);
    },
    fetchVehicleData: (data) => {
        return axios.post(`${baseUrl}/vehicle-data`, data);
    },
    checkIsPaid: (orderId) => {
        return axios.post(`${baseUrl}/check-order-is-paid?orderId=${orderId}`);
    },
    fetchFondData: () => {
        return axios.get(`${baseUrl}/fetch-fond-data`);
    }
});
